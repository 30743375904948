// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame_loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frame_loading .span_text_loading {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/loading/Loading.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAGE;EACE,+BCNW;EDOX,eAAA;EACA,gBAAA;AADJ","sourcesContent":["@use \"../../scss/\" as *;\r\n\r\n.frame_loading {\r\n  width: 100%;\r\n  height: 100vh;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n\r\n  .span_text_loading {\r\n    font-family: $global-font;\r\n    font-size: 1rem;\r\n    font-weight: 500;\r\n  }\r\n}\r\n","$mobile-width:600px;\r\n$tablet-width: 1024px;\r\n$desktop-width:4000px;\r\n$rotate-width: 950px;\r\n$global-font:  \"Lato\", sans-serif;\r\n$shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;\r\n$shadow2:rgba(0, 0, 0, 0.24) 0px 3px 8px;\r\n$color-button-linear: linear-gradient(90deg, #511013 0%, #9B191E 100%);\r\n$color-button-linear-hover: linear-gradient(100deg, #CD171F 0%, #670C10 100%);\r\n$color-data-time:#50618E"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
