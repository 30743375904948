import React, { useContext } from "react";
import "./RevenuePage.scss";
import RevenueCard from "../../components/revenuecard/RevenueCard";
import PieChart from "../../components/chart/custompiechart/CustomPieChart";
import CustomLineChart from "../../components/chart/customlinechart/CustomLineChart";
import LineChart from "../../components/chart/linechart/LineChart";
import CustomeUsaChart from "../../components/chart/customusamap/CustomUsaMap";
import IncomeCard from "../../components/incomecard/IncomeCard";
import { AppContext } from "../../App";
import { ScrollTop } from "../../utils/autoscrolltop/AutoScrollTop";
import DataTime from "../../components/datatime/DataTime";
import { getApiData } from "../../utils/getdata/getApiData";

const RevenuePage = () => {
  ScrollTop(".main_frame_outlet");
  const { apiData, Option } = useContext(AppContext);

  return (
    <div className="main_frame_revenue">
      <div className="frame_datetime_revenue">
        <DataTime />
      </div>

      <div className="main_frame_totalicome">
        <div className="frame_top_card_title_revnues">
          <div className="frame_card_total_income_revenue">
            <IncomeCard
              data={getApiData(apiData, "RevenueApi", "topRevenueCard")}
              delays={1700}
              durations={2800}
            />
          </div>

          <div className="frame_top_title_custome_usemap" data-aos="fade-down">
            <span>TOP 3 OF HIGHEST STATE</span>
          </div>
        </div>
        <div className="frame_geomap_usa_totalicome">
          <CustomeUsaChart RevenueApi={apiData.RevenueApi} Options={Option} />
        </div>
      </div>
      <div className="main_frame_totalbymonth_revenue" data-aos="zoom-in">
        <div className="frame_title_chart_revenue">
          <span>Total revenue by months</span>
        </div>
        <div className="frame_arelinechart_revenue">
          <LineChart
            classstyles="frame_parent_line_Chart_revenue"
            tooltips=""
            shows={false}
            data={getApiData(apiData, "RevenueApi", "totalRevenueMonths")}
          />
        </div>
      </div>

      <div className="main_frame_totalbyregion">
        <div className="total_revenue">
          <div className="frame_title_top_chart_revenue">
            <span>TOTAL REVENUE BY REGIONS</span>
          </div>

          <div className="frame_pieChart_revenue">
            <PieChart
              data={getApiData(apiData, "RevenueApi", "totalRevenueRegions")}
            />
          </div>
        </div>
        <div className="total_categories">
          <div className="frame_custom_line_chart_categories">
            <CustomLineChart
              title="TOP 5 OF HIGHEST CATEGORIES"
              classStyles_title="frame_title_Categories_revenue"
              span_style="span_style_revenue"
              delays={2500}
              durations={4000}
              data={getApiData(apiData, "RevenueApi", "topHighestCategories")}
            />
          </div>
        </div>
      </div>
      <div className="main_frame_cardrevenue">
        <RevenueCard
          data={getApiData(apiData, "RevenueApi", "topHighestStateMap")}
          name="TOP 5 OF STATES REVENUE"
          classstyles=""
          delays={2500}
          durations={4000}
          slice="5"
        />
        <RevenueCard
          data={getApiData(apiData, "RevenueApi", "topHighestMarkets")}
          name="TOP 5 OF MARKETS REVENUE"
          classstyles=""
          delays={2500}
          durations={4000}
          slice="5"
        />
      </div>
    </div>
  );
};

export default RevenuePage;
