// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columns_chart_budget {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .columns_chart_budget {
    width: 180%;
  }
  .columns_chart_budget [data-aos] {
    opacity: 1 !important;
    transform: translate(0) scale(1) !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/chart/columnschart/ColumnsChart.scss","webpack://./src/scss/_breakpoint.scss"],"names":[],"mappings":"AAGA;EAEI,WAAA;EACA,YAAA;AAHJ;ACGI;EDHJ;IAMQ,WAAA;EAFN;ECAM;IACI,qBAAA;IACA,2CAAA;EDEV;AACF","sourcesContent":["\r\n@use '../../../scss/' as *;\r\n\r\n.columns_chart_budget {\r\n\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    @include mobile {\r\n        width: 180%;\r\n    }\r\n}","@use './variables' as *;\r\n\r\n\r\n\r\n\r\n@mixin mobile {\r\n    @media only screen  and (max-width:$mobile-width) {\r\n        [data-aos] {\r\n            opacity: 1 !important;\r\n            transform: translate(0) scale(1) !important;\r\n        }\r\n        @content;\r\n\r\n    }\r\n    \r\n}\r\n\r\n@mixin tablet {\r\n    @media only screen  and (max-width:$tablet-width) {\r\n        [data-aos] {\r\n            opacity: 1 !important;\r\n            transform: translate(0) scale(1) !important;\r\n        }\r\n        @content;\r\n\r\n    }\r\n    \r\n}\r\n\r\n@mixin desktop {\r\n    @media only screen  and (max-width:$tablet-width) {\r\n        @content;\r\n\r\n    }\r\n    \r\n}\r\n\r\n\r\n\r\n\r\n@mixin rotate {\r\n    @media only screen  and (max-width:$rotate-width) {\r\n        @content;\r\n\r\n    }\r\n    \r\n}\r\n\r\n// Add mixins for orientation\r\n@mixin portrait {\r\n    @media only screen and (orientation: portrait) {\r\n        @content;\r\n    }\r\n}\r\n\r\n@mixin landscape {\r\n    @media only screen and (orientation: landscape) {\r\n        @content;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
