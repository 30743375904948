import React, { useState, useContext } from "react";
import "./Login.scss";
import FathomLogo from "../../../assets/menupage/Fathom-Logo.png";
import { Button, Input, message, Modal } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { LockOutlined, MailOutlined, GoogleOutlined } from "@ant-design/icons";
import {signInWithEmailAndPassword,sendPasswordResetEmail,GoogleAuthProvider,signInWithPopup,deleteUser} from "firebase/auth";
import { auth } from "../firebase-config";
import { AppContext } from "../../../App";
import { URL } from "../../../constant/url";
import axios from "axios";

const Login = ({ setAuth }) => {
  const navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { setToken } = useContext(AppContext);

  const validateInput = () => {
    if (!loginEmail) {
      message.error("Email is required.");
      return false;
    } else {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailPattern.test(loginEmail)) {
        message.error("Invalid email format.");
        return false;
      }
    }

    if (!loginPassword) {
      message.error("Password is required.");
      return false;
    }

    return true;
  };

  const login = async () => {
    if (!validateInput()) {
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      const user = userCredential.user;
      const globalToken = await userCredential.user.getIdToken();

      if (user.emailVerified) {
        setAuth(true);
        navigate("/fathom/menupage");
        const token = userCredential.user.accessToken;
        localStorage.setItem("token", token);
        setToken(globalToken);
      } else {
        message.error("Please verify your email before signing in.");
      }
    } catch (error) {
      if (error.code === "auth/invalid-credential") {
        message.error("Email or password is incorrect. Please check again.");
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post(
        `${URL}/api/user/isexists`,
        {
          email: resetEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // message.success(response.data.message);
        await sendPasswordResetEmail(auth, resetEmail);
        message.success(
          "Password reset email has been sent. Please check your inbox!"
        );
        setIsModalVisible(false);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "User does not exist") {
          message.error(
            "The user does not exist. Please proceed with registration."
          );
        } else {
          message.error("Please input a correctly formatted email address.");
        }
        console.log(error.response.data.message);
      } else {
        message.error(
          "Network error. Please check your connection and try again."
        );
      }
    }
  };

  const googleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const globalToken = await user.getIdToken();

      const allowedEmail = [
        "@fathom-accounting.com",
        "@fathominc.com",
        "@phalanxco.services",
      ];

      const isAllowedEmail = allowedEmail.some((doman) =>
        user.email.endsWith(doman)
      );
      if (!isAllowedEmail) {
        message.error(
          "Email domain is not allowed. Please use the company email."
        );
        await deleteUser(user);
        await auth.signOut();
        return;
      }

      if (user.emailVerified) {
        message.success("Login successfully.");
        navigate("/fathom/menupage");
        setAuth(true);
        const token = user.accessToken;
        localStorage.setItem("token", token);
        setToken(globalToken);
      } else {
        message.error("Please verify your email before signing in.");
      }
    } catch (error) {
      message.error("Error siging in with Google, Please try again.");
    }
  };

  return (
    <div className="main_frame_login">
      <div className="parent_frame_element_login">
        <div className="frame_elements_login">
          <div className="frame_logo_login">
            <img src={FathomLogo} alt="fathomlogo" />
          </div>
          <div>
            <div className="frame_title_signin">
              <span>Sign In</span>
            </div>
            <div className="frame_sub_title">
              <span>Welcome to Dashboard!</span>
            </div>
          </div>
          <div className="fram_item_login_form">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="frame_input">
                <div className="element_input_label_email">
                  <label htmlFor="email">Email</label>
                  <Input
                    id="email"
                    prefix={<MailOutlined />}
                    placeholder="Enter Your Email"
                    className="input_field"
                    style={{ borderColor: "gray", boxShadow: "unset" }}
                    type="email"
                    onChange={(event) => {
                      setLoginEmail(event.target.value);
                    }}
                  />
                </div>

                <div className="element_input_label_password">
                  <label htmlFor="password">Password</label>
                  <Input.Password
                    id="password"
                    prefix={<LockOutlined />}
                    placeholder="Enter Your Password"
                    className="input_field"
                    style={{ borderColor: "gray", boxShadow: "unset" }}
                    onChange={(event) => {
                      setLoginPassword(event.target.value);
                    }}
                  />
                </div>
                <div className="frame_forgot_password">
                  <Link
                    onClick={() => setIsModalVisible(true)}
                    className="forgot-password"
                  >
                    <span>Forgot password?</span>
                  </Link>
                </div>
              </div>
              <div className="frame_button_login">
                <Button
                  block
                  htmlType="submit"
                  className="color_button"
                  onClick={login}
                >
                  Sign in
                </Button>
              </div>

              <div className="frame_button_login">
                <Button
                  block
                  htmlType="submit"
                  className="color_button"
                  onClick={googleLogin}
                >
                  Continue With Google <GoogleOutlined />
                </Button>
              </div>
            </form>

            <div className="frame_create_account">
              <span className="text">Don't have an account?</span>{" "}
              <Link to="/register" className="frame_sign_up">
                <span>Sign up now</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Reset Password"
        open={isModalVisible}
        onOk={async () => {
          await handleForgotPassword();
          setResetEmail("");
        }}
        onCancel={() => {
          setIsModalVisible(false);
          setResetEmail("");
        }}
        okButtonProps={{
          style: { backgroundColor: "red", borderColor: "red" },
        }}
        className="frame_modal_login"
      >
        <Input
          placeholder="Enter your email"
          onChange={(event) => setResetEmail(event.target.value)}
          value={resetEmail}
        />
      </Modal>
    </div>
  );
};

export default Login;
