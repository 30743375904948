
export const caculatePercent = (data) =>{
      const maxValue = Math.max(...data.map((item) => item.value));

    return data.map((item) => {
        const percentage = ((item.value/maxValue) * 100).toFixed(2)
        
        return {
            ...item,
            percentage
        }
    })
}


export const caulatePercentByXvalue = (data,InputMaxValue) => {
    return data.map((item) => {
        const percentage = ((item.value/InputMaxValue) * 100).toFixed(2)
        
        return {
            ...item,
            percentage
        }
    })

}

export const caculatePercentFormatdata = (data) => {
  
  const maxValue = Math.max(...data.map((item) => item.value));

  return data.map((item) => { // Use filterdata instead of data
    const percentage = ((item.value / maxValue) * 100).toFixed(2);

    const finalPercentage = percentage > 0 ? percentage : 0
   

    return {
      ...item,
      percentage: finalPercentage
    };
  });
};






  