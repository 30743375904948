import React from "react";
import "./TransactionsPage.scss";
import TMCard from "../../components/transactioncard/TMCard";
import TotalTransactionCard from "../../components/totaltransactioncard/TotalTransactionCard";
import LineChart from "../../components/chart/linechart/LineChart";
import { AppContext } from "../../App";
import { useContext } from "react";
import DataTime from "../../components/datatime/DataTime";
import { getApiData } from "../../utils/getdata/getApiData";
import { ScrollTop } from "../../utils/autoscrolltop/AutoScrollTop";

const TransactionsPage = () => {
  ScrollTop(".main_frame_outlet");
  const { apiData } = useContext(AppContext);

  return (
    <div className="main_frame_transaction">
      <div className="frame_datetime_transaction">
        <DataTime />
      </div>
      <div className="frame_top_card">
        <TotalTransactionCard
          data={getApiData(apiData, "TransactionApi", "totaltransaction")}
          delays={3300}
          durations={4000}
        />
        <TMCard
          data={getApiData(apiData, "TransactionApi", "tophigheststates")}
          title="Top 5 of States closing"
          delays={3300}
          durations={4000}
        />
        <TMCard
          data={getApiData(apiData, "TransactionApi", "tophighestmarkets")}
          title="Top 5 of Markets closing"
          delays={3300}
          durations={4000}
        />
      </div>
      <div className="frame_transactionbymonht" data-aos="zoom-in">
        <div
          className="frame_title_chart_transaction"
          data-aos="fade-up"
          data-aos-delay="2000"
        >
          <span>TOTAL TRANSACTION BY MONTHS</span>
        </div>
        <div className="frame_cutome_layout_linechart_transaction">
          <div className="frame_line_chart_transations">
            <LineChart
              classstyles="frame_parent_line_Chart_transactions"
              data={getApiData(
                apiData,
                "TransactionApi",
                "totaltransactionmonth"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsPage;
