import React from "react";
import "./RevenueCard.scss";
import { AnimatedValue } from "../../utils/animationnumber/AmimationNmber";

const RevenueCard = ({ data, name, classstyles, delays, durations, slice }) => {
  const waitingData = data.length > 0 ? data : [];

  return (
    <div className="main_frame_revenuecard">
      <div className={`span_name ${classstyles}`}>
        <span>{name}</span>
      </div>

      <div className="frame_name_value_line">
        {waitingData.slice(0, slice).map((item, index) => (
          <div key={index} className="main_frame_name_value">
            <div className="frame_name_value">
              <div className="frame_name_item_revenuecard">
                <span>{item.name}</span>
              </div>
              <span>
                {item.value > 0 ? (
                  <AnimatedValue
                    endValue={item.value}
                    delay={delays}
                    durations={durations}
                    dollar="$"
                  />
                ) : (
                  <>
                    (
                    {
                      <AnimatedValue
                        endValue={Math.abs(item.value)}
                        delay={delays}
                        durations={durations}
                        dollar="$"
                      />
                    }{" "}
                    )
                  </>
                )}
              </span>
            </div>
            <div>
              <hr />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RevenueCard;
