import React from "react";
import "./CommissionPage.scss";
import { AppContext } from "../../App";
import { useContext } from "react";
import RevenueCard from "../../components/revenuecard/RevenueCard";
import BudgetCard from "../../components/budgetCard/BudgetCard";
import GeoMap from "../../components/chart/geomap/GeoMap";
import LineChart from "../../components/chart/linechart/LineChart";
import { ScrollTop } from "../../utils/autoscrolltop/AutoScrollTop";
import DataTime from "../../components/datatime/DataTime";
import { getApiData } from "../../utils/getdata/getApiData";

const CommissionPage = () => {
  ScrollTop(".main_frame_outlet");
  const { apiData } = useContext(AppContext);

  return (
    <div className="main_frame_commission">
      <DataTime />
      <div className="frame_top_chart" data-aos="fade-down">
        <div className="frame_title_top_chart_commission">
          <span>TOTAL AGENT'S COMMISSION BY MONTHS</span>
        </div>

        
        <div className="areline_chart_new">

        <div className="frame_arelinechart">
          <LineChart
            classstyles="frame_parent_line_Chart"
            tooltips="pin"
            shows={true}
            types="max"
            data={getApiData(apiData, "CommissionApi", "commissionmonths")}
          />
        </div>

        </div>
        
      </div>
      <div className="frame_bottom_commission" data-aos="zoom-in">
        <div className="frame_left_bottom_Commission">
          <div className="frame_geomap_commission" data-aos="zoom-in">
            <div className="frame_title_geomap_commission">
              <span>Agent's Commission by states</span>
            </div>
            <div className="frame_geomap">
              <GeoMap
                data={getApiData(apiData, "CommissionApi", "commissionstates")}
              />
            </div>
          </div>
          <div className="frame_card_commission">
            <RevenueCard
              data={getApiData(apiData, "CommissionApi", "highestmarkets")}
              name="TOP 5 OF MARKETS"
              classstyles=""
              delays={2200}
              durations={3600}
              slice="5"
            />
          </div>
        </div>
        <div className="frame_right_bottom_commission">
          <div className="frame_right_top_card">
            <BudgetCard
              data={getApiData(apiData, "CommissionApi", "totalcommission")}
              classStyle_frametransaction="frame_total_transaction"
              classStyle_titleiconvaluen="frame_title_icon_value"
              classStyle_title_icon="frame_title_icon"
              classStyle_value="frame_value"
              delays={2200}
              durations={3600}
              slice="5"
            />
          </div>

          <div
            className="frame_right_bottom_card"
            data-aos="fade-up-left"
            data-aos-delay="800"
          >
            <RevenueCard
              data={getApiData(apiData, "CommissionApi", "highestcategories")}
              name="AGENT'S COMMISSION BY CATERGORIES"
              classstyles="style_title"
              delays={2200}
              durations={3600}
              slice="7"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionPage;
