import React from "react";
import "./ExpensePageNewVersion.scss";
import { AppContext } from "../../App";
import { useContext } from "react";
import PieChart from "../../components/chart/piechart/PieChart";
import BudgetCard from "../../components/budgetCard/BudgetCard";
import img_two_line from "../../assets/expensepage/image_two_line.png";
import img_one_line from "../../assets/expensepage/image_one_line.png";
import CustomLineChart from "../../components/chart/customlinechart/CustomLineChart";
import SingleColumns from "../../components/chart/singlecolumn/SingleColumns";
import { ScrollTop } from "../../utils/autoscrolltop/AutoScrollTop";
import DataTime from "../../components/datatime/DataTime";
import HorizontalChart from "../../components/chart/horizotalchart/HorizontalChart";
import ChartToolTip from "../../components/chart/charttooltip/ChartToolTip";
import { getApiData } from "../../utils/getdata/getApiData";

const ExpensePage = () => {
  ScrollTop(".main_frame_outlet");
  const { apiData } = useContext(AppContext);

  const dataExpenseCard = getApiData(apiData, "ExpenseApi", "totalexpense");
  const expenses = dataExpenseCard?.[0] || "";
  const holdings = dataExpenseCard?.[1] || "";
  const states = dataExpenseCard?.[2] || "";

  return (
    <div className="main_frame_expense">
      <div className="frame_datetime_expense">
        <DataTime />
      </div>
      <div className="frame_top_total_expense">
        <div className="frame_main_cart_customChart">
          <div className="frame_cart_top_total_expense" data-aos="zoom-in-down">
            <BudgetCard
              data={expenses}
              classStyle_frametransaction="frame_total_expense"
              classStyle_titleiconvaluen="frame_title_icon_value"
              classStyle_title_icon="frame_title_icon"
              classStyle_value="frame_value"
              delays={2500}
              durations={3600}
              dollar="$"
            />
          </div>
          <div
            className="frame_img_line"
            data-aos="fade-up"
            data-aos-delay="1600"
          >
            <img src={img_two_line} alt="line" />
          </div>
          <div
            className="frame_cart_top_total_expense_twocard"
            data-aos="zoom-in-down"
            data-aos-delay="1900"
          >
            <div className="frame_first_columns">
              <BudgetCard
                data={holdings}
                classStyle_frametransaction="frame_total_expense_second"
                classStyle_titleiconvaluen="frame_title_icon_value"
                classStyle_title_icon="frame_title_icon"
                classStyle_value="frame_value"
                delays={2700}
                durations={3600}
                dollar="$"
              />
            </div>

            <div className="frame_second_columns">
              <BudgetCard
                data={states}
                classStyle_frametransaction="frame_total_expense_second"
                classStyle_titleiconvaluen="frame_title_icon_value"
                classStyle_title_icon="frame_title_icon"
                classStyle_value="frame_value"
                delays={2900}
                durations={3600}
                dollar="$"
              />
            </div>
          </div>
          <div
            className="frame_img_line_two"
            data-aos="fade-up"
            data-aos-delay="2400"
          >
            <img src={img_one_line} alt="line" />
          </div>
          <div
            className="frame_top_custom_lineChart_expense"
            data-aos="zoom-in-down"
            data-aos-delay="2700"
          >
            <CustomLineChart
              title="TOP 5 OF HIGHEST STATES"
              classStyles_title="frame_title_Categories_small"
              delays={3500}
              data={getApiData(apiData, "ExpenseApi", "higheststates")}
              durations={4000}
              span_style="span_style_expense"
            />
          </div>
        </div>
      </div>

      <div className="frame_total_expensebymonth" data-aos="zoom-in-up">
        <div className="frame_title_expensebymonth">
          <span>TOTAL EXPENSES BY MONTHS</span>
        </div>
        <div className="frame_cutome_layout_singlechart_expense">
          <div className="frame_singlechart_expense">
            <SingleColumns
              data={getApiData(apiData, "ExpenseApi", "expensemonths")}
            />
          </div>
        </div>
      </div>

      <div className="frame_chart_categories_new">
        <div className="frame_left_chart_catergoris">
          <div className="frame_title_chart">
            <div className="frame_title_revenue">
              <span>Total EXPENSES by Regions</span>
            </div>

            <div className="frame_cutome_layout_piechart_expense">
              <div className="frame_main_pieChart">
                <PieChart
                  data={getApiData(apiData, "ExpenseApi", "expenseregions")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="frame_right_market_expense_new">
          <HorizontalChart
            title="TOP 5 expenses by markets"
            classStyles_title="frame_title_Categories_revenue"
            data={getApiData(apiData, "ExpenseApi", "highestmarkets")}
            slice="5"
          />
        </div>
      </div>
      <div className="frame_categories_vendors_new">
        <ChartToolTip
          title="TOP 5 categories expenses by holding"
          data={getApiData(apiData, "ExpenseApi", "highestcategories")}
          slice="5"
          style_baritem_index="color_line_bar_z_index "
          style_baritem_index_active="color_line_bar_z_index_active"
        />
      </div>
      <div className="frame_categories_vendors_new_bottom">
        <ChartToolTip
          title="TOP 5 categories expenses by States"
          data={getApiData(apiData, "ExpenseApi", "highestvendors")}
          slice="5"
          style_baritem_index="color_line_bar2_z_index"
          style_baritem_index_active="color_line_bar2_z_index_active"
        />
      </div>
    </div>
  );
};

export default ExpensePage;
