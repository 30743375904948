import React from "react";
import "./ExpensePage.scss";
import { AppContext } from "../../App";
import { useContext } from "react";
import RevenueCard from "../../components/revenuecard/RevenueCard";
import PieChart from "../../components/chart/piechart/PieChart";
import BudgetCard from "../../components/budgetCard/BudgetCard";
import img_two_line from "../../assets/expensepage/image_two_line.png";
import img_one_line from "../../assets/expensepage/image_one_line.png";
import CustomLineChart from "../../components/chart/customlinechart/CustomLineChart";
import SingleColumns from "../../components/chart/singlecolumn/SingleColumns";
import { ScrollTop } from "../../utils/autoscrolltop/AutoScrollTop";
import DataTime from "../../components/datatime/DataTime";
import CardToolTip from "../../components/cardtooltip/CardToolTip";


const ExpensePage = () => {
  ScrollTop(".main_frame_outlet");
  const { apiData } = useContext(AppContext);




  const expenses = apiData.ExpenseApi !== "" ?  apiData.ExpenseApi.totalexpense[0] : "";
  const holdings = apiData.ExpenseApi !== "" ? apiData.ExpenseApi.totalexpense[1] : "";
  const states = apiData.ExpenseApi !== "" ? apiData.ExpenseApi.totalexpense[2] : "";

  return (
    <div className="main_frame_expense">
      <div className="frame_datetime_expense">
        <DataTime />
      </div>
      <div className="frame_top_total_expense">
        <div className="frame_main_cart_customChart">
          <div className="frame_cart_top_total_expense" data-aos="zoom-in-down">
            <BudgetCard
              data={expenses}
              classStyle_frametransaction="frame_total_expense"
              classStyle_titleiconvaluen="frame_title_icon_value"
              classStyle_title_icon="frame_title_icon"
              classStyle_value="frame_value"
              delays={2500}
              durations={3600}
              dollar="$"
            />
          </div>
          <div
            className="frame_img_line"
            data-aos="fade-up"
            data-aos-delay="1600"
          >
            <img src={img_two_line} alt="line" />
          </div>
          <div
            className="frame_cart_top_total_expense_twocard"
            data-aos="zoom-in-down"
            data-aos-delay="1900"
          >
            <div className="frame_first_columns">
              <BudgetCard
                data={holdings}
                classStyle_frametransaction="frame_total_expense_second"
                classStyle_titleiconvaluen="frame_title_icon_value"
                classStyle_title_icon="frame_title_icon"
                classStyle_value="frame_value"
                delays={2700}
                durations={3600}
                dollar="$"
              />
            </div>

            <div className="frame_second_columns">
              <BudgetCard
                data={states}
                classStyle_frametransaction="frame_total_expense_second"
                classStyle_titleiconvaluen="frame_title_icon_value"
                classStyle_title_icon="frame_title_icon"
                classStyle_value="frame_value"
                delays={2900}
                durations={3600}
                dollar="$"
              />
            </div>
          </div>
          <div
            className="frame_img_line_two"
            data-aos="fade-up"
            data-aos-delay="2400"
          >
            <img src={img_one_line} alt="line" />
          </div>
          <div
            className="frame_top_custom_lineChart"
            data-aos="zoom-in-down"
            data-aos-delay="2700"
          >
            <CustomLineChart
              title="TOP 5 OF HIGHEST STATES"
              classStyles_title="frame_title_Categories_small"
              delays={3500}
              data={ apiData.ExpenseApi !== "" ? apiData.ExpenseApi.higheststates : ""}
              durations={4000}
              span_style="span_style_expense"
            />
          </div>
        </div>
      </div>

      <div className="frame_total_expensebymonth" data-aos="zoom-in-up">
        <div className="frame_title_expensebymonth">
          <span>TOTAL EXPENSES BY MONTHS</span>
        </div>
        <div className="frame_cutome_layout_singlechart_expense">
          <div className="frame_singlechart_expense">
            <SingleColumns data={  apiData.ExpenseApi !== "" ? apiData.ExpenseApi.expensemonths : ""} />
          </div>
        </div>
      </div>

      <div className="frame_chart_categories">
        <div className="frame_left_chart_catergoris">
          <div className="frame_title_chart">
            <div className="frame_title_revenue">
              <span>Total EXPENSES by Regions</span>
            </div>

            <div className="frame_cutome_layout_piechart_expense">
              <div className="frame_main_pieChart">
                <PieChart data={  apiData.ExpenseApi !== "" ? apiData.ExpenseApi.expenseregions : ""} />
              </div>
            </div>
          </div>
        </div>

        <div className="frame_right_market_expense">
          <RevenueCard
            data={ apiData.ExpenseApi !== "" ? apiData.ExpenseApi.highestmarkets : ""}
            name="TOP 5 OF Markets EXPENSES"
            classstyles=""
            delays={2500}
            durations={3600}
            slice="5"
          />
        </div>
      </div>
      <div className="frame_categories_vendors">
      
        <CardToolTip
          data={  apiData.ExpenseApi !== "" ? apiData.ExpenseApi.highestcategories : ""}
          name="TOP 5 OF Categories EXPENSES by holding"
          classstyles=""
          delays={2500}
          durations={3600}
          slice="5"
        />

          <CardToolTip
          data={ apiData.ExpenseApi !== "" ? apiData.ExpenseApi.highestvendors : ""}
          name="TOP 5 OF Categories EXPENSES by state"
          classstyles=""
          delays={2500}
          durations={3600}
          slice="5"
        />
      </div>
    </div>
  )
};

export default ExpensePage;
