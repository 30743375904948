import formatNumberWithCommas from "../../../format/formatNumberWithCommas";
const createChartOptions = (highestStates, coordinates, revenueApi) => {


  // Define a responsive symbolSize based on window size or container width
  const symbolSize = window.innerWidth < 768 ? 20 : 40;

  // Adjust the position and padding based on screen size
  const labelPosition = window.innerWidth < 768 ? [10, -20] : [19, -35];
  const labelPadding = window.innerWidth < 768 ? [5, 10] : [10, 15];

  return {
    tooltip: {
      trigger: "item",
      showDelay: 0,
      transitionDuration: 0.2,
      formatter: function (params) {
        return `${params.name}: ${
          formatNumberWithCommas(params.value) !== "NaN"
            ? "$" + formatNumberWithCommas(params.value)
            : "$0"
        }`;
      },
    },
    toolbox: {
      show: true,
      left: "left",
      top: "top",
    },

    zoom: 1.4,

    series: [
      {
        name: "Agent Commission",
        type: "map",
        roam: false,
        map: "USA",
        zoom: 1.2,
        emphasis: {
          label: { show: true },
        },
        data: revenueApi.topHighestStateMap,
        markPoint: {
          symbol:
            "image://https://img.icons8.com/?size=100&id=13778&format=png&color=000000",
          symbolSize: symbolSize, // Responsive symbol size
          label: {
            show: true,
            formatter: "{b}\n{c}",
            backgroundColor: "rgba(108, 116, 147, 0.6)",
            borderColor: "#ddd",
            color: "white",
            borderWidth: 1,
            borderRadius: 10,
            position: labelPosition, // Responsive position
            padding: labelPadding, // Responsive padding
            textStyle: {
              fontSize: 14,
              fontFamily: "Arial",
              align: "center",
              verticalAlign: "middle",
              lineHeight: 18,
            },
          },
          data: highestStates.map((state) => ({
            name: state.name,
            coord: coordinates[state.name],
            value: `$${formatNumberWithCommas(state.value)}`,
            tooltip: {
              show: false,
            },
            animation: true,
            animationDuration: 2000,
            animationEasing: "easeOutBounce",
          })),
        },

        itemStyle: {
          areaColor: "#2a2a2c",
          borderColor: "#8f8f8f",
          borderWidth: 0.5,
          emphasis: {
            areaColor: "white",
            borderColor: "#8f8f8f",
            borderWidth: 1,
            label: {
              color: "black",
            },
          },
        },
      },
    ],
  };
};

export default createChartOptions;
