import React from 'react';
import './IncomeCard.scss'
import Wallet from '../../assets/revenuepage/icon _wallet.png';
import { AnimatedValue } from '../../utils/animationnumber/AmimationNmber';


const IncomeCard = ({ data, delays, durations }) => {
  
  return (
    <div className='frame_main_income_card'>
      <div className='frame_title_wallet'>
        <span>{data.name ? data.name : ""}</span>
        <img src={Wallet} alt='wallet' />
      </div>
      <div className='frame_income_value' data-aos="fade-up-left">
        <span>{<AnimatedValue endValue={data.value} delay={delays} durations={durations} dollar="$" />}</span>
      </div>
    </div>
  )
}

export default IncomeCard
