import React from "react";
import ReactECharts from "echarts-for-react";
import "./CustomUsaMap.scss";
import { Flex, Spin } from "antd";

const CustomUsaMap = ({RevenueApi,Options}) => {

  
  return (
    <div className="frame_parent_customusamap">
      {Options ? (
        <ReactECharts
          option={Options}
          dataProducts ={RevenueApi.tophigheststateMap}
          style={{ height: "100%", width: "100%" }}
        />
        ) : (
        <div className="frame_loading">
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </div>
      )} 
    </div>
  );
};

export default CustomUsaMap;
