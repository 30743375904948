const menubar = [
  {
    name: "Revenue & Income",
    link: "/fathom/dashboards",
  },
  {
    name: "Agent's Commission",
    link: "/fathom/dashboards/commission",
  },
  {
    name: "Total Transactions",
    link: "/fathom/dashboards/transactions",
  },
  {
    name: "Expenses",
    link: "/fathom/dashboards/expense",
  },
  {
    name: "Budget",
    link: "/fathom/dashboards/budget",
  },
];

export default menubar;
