import React from "react";
import "./HorizontalChart.scss";
import {caculatePercent} from '../../../utils/percentage/CaculatePercent';
import BarItemHorizontal from "../horizotalchart/baritem/BarItemHorizontal";

const HorizontalChart = ({ title, data }) => {
    const waitingData = data.length > 0 ? caculatePercent(data) : [];
  return (
    <div className="frame_horizontal_chart">
      <div className="frame_title_horizotal_Chart">
        <span>{title}</span>
      </div>

      <div className="frame_main_name_line_chart_horizontal">
        {waitingData.slice(0, 5).map((item, index) => (
          <div className="frame_custom_barItem" key={index}>
            <BarItemHorizontal
              key={index}
              name={item.name}
              value={item.value}
              percentage={item.percentage}
              
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalChart;
