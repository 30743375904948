import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route,Routes,Navigate,} from "react-router-dom";
import MenuPage from "../pages/menu_page/MenuPage";
import Dashboards from "../pages/dashboards/Dashboards";
import Revenues from "../subpages/revenue/RevenuePage";
import Transactions from "../subpages/transactions/TransactionsPage";
import Expense from "../subpages/expense/ExpensePageNewVersion";
import Expense2 from "../subpages/expense/ExpensePage";
import Commission from "../subpages/commission/CommissionPage";
import Budget from "../subpages/budget/BudgetPage";
import Login2 from "../pages/authentification_page/login_page/Login2";
import Register2 from "../pages/authentification_page/register_page/Register2";
import NotFound from "../pages/not_found_page/NotFound";
import { jwtDecode } from "jwt-decode";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../pages/authentification_page/firebase-config";
import { message } from "antd";
import { RefreshToken } from "../utils/expiredtoken/ExpiredToken";
import { AppContext } from "../App";

const ProtectedRoute = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/" />;
};

const Routers = () => {
  const { setToken } = useContext(AppContext);

  // check authenticated by token defaut
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp;
        // console.log("token is loadpage", expirationTime);
        const convertTimeNow = Math.floor(Date.now() / 1000);
        // console.log("token time loadpage", expirationTime - convertTimeNow);
        if (convertTimeNow - expirationTime > RefreshToken) {
          return false;
        } else {
          return true;
        }
      } catch (error) {
        console.error("Token decoding failed:", error);
        return false; // Invalid token
      }
    }
    return false; // No token found
  });

  const setAuth = (auth) => {
    setIsAuthenticated(auth);
  };

  useEffect(() => {
    // Firebase onAuthStateChanged listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const convertTimeNow = Math.floor(Date.now() / 1000);
        user.getIdToken().then((idToken) => {
          const decodedToken = jwtDecode(idToken);
          const expirationTime = decodedToken.exp;

          const defaultToken = localStorage.getItem("token");

          if (defaultToken) {
            const decodedDefaultToken = jwtDecode(defaultToken);
            if (convertTimeNow - decodedDefaultToken.exp > RefreshToken) {
              localStorage.removeItem("token");
              setIsAuthenticated(false);
              console.log("logout is running");
              message.error("Token is expired");
            } else if (expirationTime - convertTimeNow > 0) {
              localStorage.setItem("token", idToken);
              setToken(idToken);
              // const token = localStorage.getItem("token");
              // const decode = jwtDecode(token);
              // const time = decode.exp;
              // console.log("token", time);
              // console.log("remaining time", expirationTime - convertTimeNow);
            }
          }
        });
      } else {
        // User is logged out
        localStorage.removeItem("token");
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [setToken]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={isAuthenticated ? (<Navigate to="/fathom/dashboards" />) : (<Login2 setAuth={setAuth} />)}/>
        <Route path="/register" element={<Register2 />} />
        <Route path="/fathom/menupage" element={isAuthenticated ? <MenuPage /> : <Navigate to="/" />} />
        <Route path="/fathom/dashboards/*" element={ <ProtectedRoute isAuthenticated={isAuthenticated} element={<Dashboards />} />}>
          <Route index element={ <ProtectedRoute isAuthenticated={isAuthenticated} element={<Revenues />} /> } />
          <Route path="transactions" element={ <ProtectedRoute isAuthenticated={isAuthenticated} element={<Transactions />} />}/>
          <Route path="expense" element={ <ProtectedRoute isAuthenticated={isAuthenticated} element={<Expense />} /> }/>
          <Route path="expense2" element={ <ProtectedRoute isAuthenticated={isAuthenticated} element={<Expense2 />} /> } />
          <Route path="commission" element={ <ProtectedRoute isAuthenticated={isAuthenticated} element={<Commission />}/>}/>
          <Route path="budget" element={ <ProtectedRoute isAuthenticated={isAuthenticated} element={<Budget />} />}/>
          <Route path="*" element={<NotFound/>}/>
        </Route>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </Router>
  );
};

export default Routers;
