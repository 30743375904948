import React, { useState } from "react";
import "./Register2.scss";
import FathomLogo from "../../../assets/menupage/Fathom-Logo.png";
import { Button, Input, Modal, message, Flex, Spin } from "antd";
import {
  LockOutlined,
  MailOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { auth } from "../firebase-config";

const Register = () => {
  const navigate = useNavigate();
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [err, setErr] = useState(null);

  const validateInput = () => {
    if (!displayName) {
      message.error("Name is required.");
      setErr(true);
      return false;
    }

    if (!registerEmail) {
      message.error("Email is required.");
      setErr(true);
      return false;
    } else {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailPattern.test(registerEmail)) {
        message.error("Invalid email format.");
        setErr(true);
        return false;
      }

      const allowedDomains = [
        "@fathom-accounting.com",
        "@fathominc.com",
        "@phalanxco.services",
      ];

      const isAllowedDomain = allowedDomains.some((domain) =>
        registerEmail.endsWith(domain)
      );
      if (!isAllowedDomain) {
        message.error("Please use your company email.");
        setErr(true);
        return false;
      }
    }

    if (!registerPassword) {
      message.error("Password is required.");
      setErr(true);
      return false;
    }

    return true; // All validations passed
  };

  const register = async () => {
    if (!validateInput()) {
      setErr(true);
      return; // Stop execution if validation fails
    } else {
      setErr(false);
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      const user = userCredential.user;

      await updateProfile(user, { displayName });
      await sendEmailVerification(user);
      setIsModalVisible(true);
    } catch (error) {
      if (error.code === "auth/weak-password") {
        message.error("Your password is too weak.");
        setErr(true);
      }

      if (error.code === "auth/email-already-in-use") {
        message.error("Email is already in use. Please check again.");
        setErr(true);
      }
    }
  };

  const handleGoToLogin = () => {
    setIsModalVisible(false);
    navigate("/");
  };

  return (
    <div className="main_frame_register">
      <div className="parent_frame_element_register">
        <div className="frame_element_register">
          <div className="frame_logo_login">
            <img src={FathomLogo} alt="fathomlogo" />
          </div>
          <div>
            <div className="frame_title_signin">
              <span>Sign Up</span>
            </div>
            <div className="frame_sub_title">
              <span>Fill in your details below to get started.</span>
            </div>
          </div>
          <div className="fram_item_login_form">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="frame_input">
                <div className="element_input_label_name_register">
                  <label htmlFor="displayName">Your Name</label>
                  <Input
                    id="displayName"
                    placeholder="Enter Your Name"
                    className="input_field "
                    style={{ borderColor: "gray", boxShadow: "unset" }}
                    onChange={(event) => setDisplayName(event.target.value)}
                  />
                </div>

                <div className="element_input_label_email_register">
                  <label htmlFor="email">Email</label>
                  <Input
                    id="email"
                    prefix={<MailOutlined />}
                    placeholder="Enter Your Email"
                    className="input_field"
                    style={{ borderColor: "gray", boxShadow: "unset" }}
                    type="email"
                    onChange={(event) => setRegisterEmail(event.target.value)}
                  />
                </div>
                <div className="element_input_label_password">
                  <label htmlFor="password">Password</label>
                  <Input.Password
                    id="password"
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Enter Your Password"
                    className="input_field"
                    style={{ borderColor: "gray", boxShadow: "unset" }}
                    onChange={(event) =>
                      setRegisterPassword(event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="frame_button_register">
                <Button
                  block
                  htmlType="submit"
                  className="color_button"
                  onClick={register}
                >
                  {err === true || err === null ? (
                    <span> Sign Up</span>
                  ) : (
                    <Flex align="center" gap="middle">
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: "white" }}
                            spin
                          />
                        }
                      />
                    </Flex>
                  )}
                </Button>
              </div>
            </form>

            <div className="frame_create_account">
              <Link to="/">
                <ArrowLeftOutlined className="icon_back" />
                <span className="text" style={{ color: "white" }}>
                  Back To Login
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Verify Your Email"
        open={isModalVisible}
        onOk={handleGoToLogin}
        footer={[
          <Button
            key="login"
            type="primary"
            onClick={handleGoToLogin}
            style={{ backgroundColor: "red" }}
          >
            Go to Login
          </Button>,
        ]}
        closable={false}
        className="frame-modal"
        style={{ width: "100%" }}
      >
        <div className="custom-modal-body">
          <p>
            A verification email has been sent to {registerEmail} .Please check
            your inbox and verify your email to complete the registration.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Register;
