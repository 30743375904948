// src/api.js
import axios from "axios";
import { URL } from "../../constant/url";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const createApiWithToken = (token) => {
  const requestToken = token || getTokenFromLocalStorage();
  const headers = {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  };

  if (requestToken) {
    headers["Authorization"] = `${requestToken}`;
  }

  return axios.create({
    baseURL: `${URL}/api/commission`,
    headers: headers,
  });
};

export const fetchTotalCommissionMonth = (token) => {
  const api = createApiWithToken(token);
  return api.get("/commissionbymonths");
};

export const fetchCommissionState = (token) => {
  const api = createApiWithToken(token);
  return api.get("/commissionstate");
};
export const fetchTotalCard = (token) => {
  const api = createApiWithToken(token);
  return api.get("/commissiontotalcard");
};
export const fetchHighestMarkets = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophighestmarkets");
};
export const fetchHighestCategories = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophighestcategories");
};
