// import React from "react";
// import ReactECharts from "echarts-for-react";
// import './CustomPieChart.scss';
// import { getTooltipContent } from "../../../utils/formaterpiechart/FormaterPieChart";

// const CustomizedPieChart = ({ data }) => {
//   const waitingData = data.length > 0 ? data : [];

//   const option = {
//     title: {
//       text: "",
//       left: "center",
//       top: 20,
//       textStyle: {
//         color: "none",
//       },
//     },

//     tooltip: {
//       trigger: 'item',
//       formatter: (params) => getTooltipContent(params,waitingData),
//       backgroundColor: "rgba(95, 97, 118, 0.99)",
//       borderColor: '#fff',
//       borderWidth: 1,
//       padding: 10,
//       position: function (point, params, dom, rect, size) {
//         const windowWidth = window.innerWidth; // Get window width
//         let x, y;

//         if (windowWidth < 768) {
//           // Center the tooltip when width < 768
//           x = (windowWidth - size.contentSize[0]) / 2; // Center horizontally
//           y = point[1] - size.contentSize[1] + 10; // 10 pixels above the cursor
//         } else {
//           x = point[0] - 10; // 10 pixels to the right of the cursor
//           y = point[1] - size.contentSize[1] + 10; // 10 pixels above the cursor
//         }

//         return [x, y]; // Return the new position
//       },
//       textStyle: {
//         color: 'white',
//       },
//     },
//     visualMap: {
//       show: false,
//       min: 80,
//       max: 600,
//       inRange: {
//         colorLightness: [0, 1],
//       },
//     },
//     series: [
//       {
//         name: "Total Revenue",
//         type: "pie",
//         radius: "55%",
//         center: ["50%", "50%"],
//         data: waitingData.sort((a, b) => a.value - b.value),
//         roseType: "radius",
//         label: {
//           color: "white",
//           fontSize: 18,
//           formatter: "{b}",
//         },
//         labelLine: {
//           lineStyle: {
//             color: "white",
//             width: 2,
//             type: "solid",
//           },
//           smooth: 0.2,
//           length: 20,
//           length2: 30,
//         },
//         itemStyle: {
//           //   color: '#c23531',
//           //   shadowBlur: 200,
//           //   shadowColor: 'rgba(0, 0, 0, 0.5)'
//         },
//         animationType: "expand", // Animation type
//         animationEasing: "linear",
//         animationDelay: (idx) => idx * 100,
//         animationDelayUpdate: (idx) => idx * 300,
//       },
//     ],
//   };

//   return (
//     <div className="frame_custome_piechart">
//       <ReactECharts option={option} style={{ height: "100%", width: "100%" }} />
//     </div>
//   );
// };

// export default CustomizedPieChart;

import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import "./CustomPieChart.scss";
import { getTooltipContent } from "../../../utils/formaterpiechart/FormaterPieChart";

const CustomizedPieChart = ({ data }) => {
  const [darkScreenVisible, setDarkScreenVisible] = useState(false);

  const [parsedData, setParsedData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const waitingData = data.map((item) => ({
        ...item,
        value: parseFloat(item.value),
        elements: JSON.parse(item.elements.replace(/'/g, '"')).sort(
          (prv, curr) => curr.value - prv.value
        ),
      }));
      setParsedData(waitingData);
    }
  }, [data]);

  useEffect(() => {
    const chartDom = document.getElementById("main");
    const myChart = echarts.init(chartDom);

    const option = {
      title: {
        text: "",
        left: "center",
        top: 20,
        textStyle: {
          color: "none",
        },
      },

      tooltip: {
        trigger: "item",
        formatter: function (params) {
          setDarkScreenVisible(true);
          return getTooltipContent(params, parsedData);
        },
        backgroundColor: "rgba(95, 97, 118, 0.99)",
        borderColor: "#fff",
        borderWidth: 1,
        padding: 10,
        position: function (point, params, dom, rect, size) {
          const windowWidth = window.innerWidth; // Get window width
          let x, y;

          if (windowWidth < 768) {
            // Center the tooltip when width < 768
            x = (windowWidth - size.contentSize[0]) / 2; // Center horizontally
            y = point[1] - size.contentSize[1] + 10; // 10 pixels above the cursor
          } else {
            x = point[0] - 10; // 10 pixels to the right of the cursor
            y = point[1] - size.contentSize[1] + 10; // 10 pixels above the cursor
          }

          return [x, y]; // Return the new position
        },
        textStyle: {
          color: "white",
        },
      },
      visualMap: {
        show: false,
        min: 80,
        max: 600,
        inRange: {
          colorLightness: [0, 1],
        },
      },
      series: [
        {
          name: "Total Revenue",
          type: "pie",
          radius: "55%",
          center: ["50%", "50%"],
          data: parsedData.sort((a, b) => a.value - b.value),
          roseType: "radius",
          label: {
            color: "white",
            fontSize: 18,
            formatter: "{b}",
          },
          labelLine: {
            lineStyle: {
              color: "white",
              width: 2,
              type: "solid",
            },
            smooth: 0.2,
            length: 20,
            length2: 30,
          },
          itemStyle: {
            //   color: '#c23531',
            //   shadowBlur: 200,
            //   shadowColor: 'rgba(0, 0, 0, 0.5)'
          },
          animationType: "expand", // Animation type
          animationEasing: "linear",
          animationDelay: (idx) => idx * 100,
          animationDelayUpdate: (idx) => idx * 300,
        },
      ],
    };

    myChart.setOption(option);
    myChart.on("mouseover", (params) => {
      if (params.componentType !== "series") {
        setDarkScreenVisible(false);
      }
    });

    myChart.on("mouseout", () => {
      setDarkScreenVisible(false);
    });

    const handleResize = () => {
      myChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.addEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, [data, parsedData]);

  return (
    <div className="frame_custome_piechart">
      {darkScreenVisible && (
        <div id="darkScreen" className="dark-screen active"></div>
      )}
      <div id="main" className="frame_piechart_revenue"></div>
    </div>
  );
};

export default CustomizedPieChart;
