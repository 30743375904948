
// import formatNumberWithCommas from "../../format/formatNumberWithCommas";


// export const getTooltipContent = (params,data) => {

//   const waitingData = data.length > 0 ? data : []

//   console.log("test",waitingData)


//     const item = waitingData[params.dataIndex];
  
//     // Get window width for responsive design
//     const windowWidth = window.innerWidth;
  
//     // Change font size based on width
//     const tooltipFontSize = windowWidth < 768 ? '16px' : '18px'; 
//     const childrenFontSize = windowWidth < 768 ? '12px' : '14px'; 
  
//     // Change padding based on width
//     const tooltipPadding = windowWidth < 768 ? '0.5rem 0.5rem' : '1rem 1rem'; 
  
//     // Calculate the total value of all regions
//     const totalValue = waitingData.reduce((sum, region) => sum + region.value, 0);
  
//     // Calculate the percentage of the current region
//     const regionPercentage = ((item.value / totalValue) * 100).toFixed(2);
  
//     const children = item.elements
//       .map(child => `
//         <div style="font-size: ${childrenFontSize}; color: white; margin-top: 20px ; text-align: left; display: flex; justify-content: space-between;">
//           <div><span style="font-size:1.2rem;">${child.name}</span></div>
//           <div><span style="font-size:1.2rem;">$${formatNumberWithCommas(child.value)}</span></div>
//         </div>
//         <div><hr style="color:white; border: none; height: 3px; background-color: white;"></hr></div>`)
//       .join('');
  
//     return `
//       <div style="width: 100%;  font-size: ${tooltipFontSize}; font-weight: bold; color: white; text-align: left; padding: ${tooltipPadding};">
//         <strong>${item.name}</strong>: $${formatNumberWithCommas(item.value)} (${regionPercentage}%)
//       </div>
//       <div style="margin-top: 5px; width: 100%;">
//         ${children}
//       </div>
//     `;
// };

import formatNumberWithCommas from "../../format/formatNumberWithCommas";

export const getTooltipContent = (params, data) => {
  const waitingData = data && data.length > 0 ? data : [];

  // Ensure params.dataIndex is valid
  if (!waitingData[params.dataIndex]) {
    return 'No data available'; 
  }

  const item = waitingData[params.dataIndex];
  
  // Get window width for responsive design
  const windowWidth = window.innerWidth;
  
  // Change font size based on width
  const tooltipFontSize = windowWidth < 768 ? '16px' : '18px'; 
  const childrenFontSize = windowWidth < 768 ? '12px' : '14px'; 
  
  // Change padding based on width
  const tooltipPadding = windowWidth < 768 ? '0.5rem 0.5rem' : '1rem 1rem'; 
  
  // Calculate the total value of all regions
  const totalValue = waitingData.reduce((sum, region) => sum + region.value, 0);
  
  // Calculate the percentage of the current region
  const regionPercentage = ((item.value / totalValue) * 100).toFixed(2);
  
  const children = item.elements
    .slice(0,5).map(child => `
      <div style="font-size: ${childrenFontSize}; color: white; margin-top: 20px ; text-align: left; display: flex; justify-content: space-between;">
        <div><span style="font-size:1.2rem;">${child.name}</span></div>
        <div><span style="font-size:1.2rem;">$${formatNumberWithCommas(child.value)}</span></div>
      </div>
      <div><hr style="color:white; border: none; height: 3px; background-color: white;"></hr></div>`)
    .join('');

  return `
    <div style="width: 100%;  font-size: ${tooltipFontSize}; font-weight: bold; color: white; text-align: left; padding: ${tooltipPadding};">
      <strong>${item.name}</strong>: $${formatNumberWithCommas(item.value)} (${regionPercentage}%)
    </div>
    <div style="margin-top: 5px; width: 100%;">
      ${children}
    </div>
  `;
};

