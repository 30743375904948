// Logout.js
import React from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase-config";
import {LogoutOutlined } from "@ant-design/icons";
import './Logout.scss'
const Logout = () => {

  const logout = async () => {
    await signOut(auth);
    localStorage.removeItem("token");

  };

  return <div onClick={logout} className="frame_logout_icon"> <LogoutOutlined className="icon_logout" style={{fontSize:"19px"}} />Sign Out </div>;
};

export default Logout;
