import { useEffect, useState } from "react";
import {
  fetchTopRevenueCard,
  fetchTopHighestStateMap,
  fetchTotalRevenueMonths,
  fetchTotalRevenueRegions,
  fetchTopHighestCategories,
  fetchTopHighestState,
  fetchTopHighestMarkets,
} from "./getApi.js";

const useDashboardData = (token) => {
  const [RevenueApi, setRevenueApi] = useState({
    topRevenueCard: "",
    topHighestStateMap: "",
    totalRevenueMonths: "",
    totalRevenueRegions: "",
    topHighestCategories: "",
    topHighestState: "",
    topHighestMarkets: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          topRevenueCardRes,
          topHighestStateMapRes,
          totalRevenueMonthsRes,
          totalRevenueRegionsRes,
          topHighestCategoriesRes,
          topHighestStateRes,
          topHighestMarketsRes,
        ] = await Promise.all([
          fetchTopRevenueCard(token),
          fetchTopHighestStateMap(token),
          fetchTotalRevenueMonths(token),
          fetchTotalRevenueRegions(token),
          fetchTopHighestCategories(token),
          fetchTopHighestState(token),
          fetchTopHighestMarkets(token),
        ]);

        setRevenueApi({
          topRevenueCard: topRevenueCardRes.data, // access data from axios
          topHighestStateMap: topHighestStateMapRes.data,
          totalRevenueMonths: totalRevenueMonthsRes.data,
          totalRevenueRegions: totalRevenueRegionsRes.data,
          topHighestCategories: topHighestCategoriesRes.data,
          topHighestState: topHighestStateRes.data,
          topHighestMarkets: topHighestMarketsRes.data,
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return { RevenueApi, loading, error };
};

export default useDashboardData;
