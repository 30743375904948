import { useEffect } from "react";

export const ScrollTop = (element) => {
  useEffect(() => {
    const mainFrame = document.querySelector(element);
    if (mainFrame) {
      mainFrame.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [element]);
};
