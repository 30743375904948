import React from 'react';
import './DataTime.scss'

const DataTime = () => {
  return (

    <div className='frame_data_time'>
    <span>Data date : 1/1/2024 - 9/30/2024</span>  
    </div>
  )
}

export default DataTime
