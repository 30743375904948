// const formatNumberWithCommas = (number) => {
//     // Convert the number to a string

    
//     const numberString = number.toString();
    
//     // Split the string into parts separated by every three digits from the end
//     const parts = [];
//     for (let i = numberString.length - 1, j = 0; i >= 0; i--, j++) {
//         if (j > 0 && j % 3 === 0) {
//             parts.unshift(',');
//         }
//         parts.unshift(numberString[i]);
//     }
    
//     // Join the parts together to form the formatted number
//     return parts.join('');
// }


// export default formatNumberWithCommas

const formatNumberWithCommas = (number) => {
    // Convert the number to a string and split into integer and decimal parts
    const checkNumber = number !== 0 ? number : 0
    const [integerPart, decimalPart] = checkNumber.toString().split('.');

    // Add commas to the integer part
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Return the formatted integer part with the decimal part if it exists
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
};

export default formatNumberWithCommas;
