import axios from "axios";
import { URL } from "../../constant/url";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const createApiWithToken = (token) => {
  const requestToken = token || getTokenFromLocalStorage();
  const headers = {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  };

  if (requestToken) {
    headers["Authorization"] = `${requestToken}`;
  }

  return axios.create({
    baseURL: `${URL}/api/expense`,
    headers: headers,
  });
};

export const fetchTotalExpense = (token) => {
  const api = createApiWithToken(token);
  return api.get("/totalexpense");
};
export const fetchHighestStates = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophigheststates");
};
export const fetchExpenseMonths = (token) => {
  const api = createApiWithToken(token);
  return api.get("/totalexpensemonths");
};
export const fetchExpenseRegions = (token) => {
  const api = createApiWithToken(token);
  return api.get("/totalexpenseregions");
};

export const fetchHighestMarkets = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophighestmarkets");
};
export const fetchHighestCategories = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophighestcategoriesholding");
};
export const fetchHighestVendor = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophighestvendors");
};
