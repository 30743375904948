import React, { useState } from "react";
import "./App.css";
import Routers from "./routers/Routers";
import "aos/dist/aos.css";
import useAos from "./utils/aos/AosAnimation";

function App() {
  useAos();

  const [token, setToken] = useState(null);
  const [Option, setOption] = useState("");
  const [apiData, setApiData] = useState({
    RevenueApi: "",
    CommissionApi: "",
    TransactionApi: "",
    ExpenseApi: "",
    BudgetApi: "",
  });

  return (
    <div className="App">
      <AppContext.Provider
        value={{
          apiData,
          setApiData,
          Option,
          setOption,
          token,
          setToken,
        }}
      >
        <Routers />
      </AppContext.Provider>
    </div>
  );
}

export const AppContext = React.createContext();

export default App;
