import React from "react";
import "./BarItemHorizontal.scss";

import { AnimatedValue } from "../../../../utils/animationnumber/AmimationNmber";
const BarItemHorizontal = ({
  name,
  value,
  percentage,
  delays,
  durations,
  span_style,
}) => {
  const itemStyles = {
    width: `${percentage}%`,
  };

  return (
    <div className="main_frame_barItem_horizontal">
      <div className="frame_categories_value_horizontal">
        <div className="frame_categories_horizontal_new">
          <span>{name}</span>
        </div>

        <div className="fram_parent_linechart_horizontal">
          <div
            style={{ ...itemStyles }}
            className="frame_line_percentage_horizontal_chart"
          />
          <span>
            {
              <AnimatedValue
                endValue={value}
                delay={delays}
                durations={durations}
                span_style={span_style}
                dollar="$"
              />
            }
          </span>
        </div>
      </div>
    </div>
  );
};

export default BarItemHorizontal;
