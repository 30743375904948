import React, { useState, useEffect } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import menubar from "../../data/menubar";
import "./DashBoard.scss";
import LogoMenuBar from "../../assets/menubar/new_logo_menubar.png";
import DrawerMode from "../../components/drawer/Drawer";
import Logout from "../authentification_page/logout/Logout";
import { AppContext } from "../../App";
import { useContext } from "react";
import Loading from "../../components/loading/Loading";
import useDashboardDataBudget from "../../apis/budget/hooks";
import useDashboardDataCommission from "../../apis/commission/hooks";
import useDashboardDataTransaction from "../../apis/transaction/hooks";
import useDashboardDataRevenue from "../../apis/revenue/hooks";
import useDashboardDataExpense from "../../apis/expense/hook";
import useFetchUsaMapData from "../../components/chart/customusamap/FetchUsaMap";
import useMapChartOptions from "../../components/chart/customusamap/useMapChartOptions";

const Dashboards = () => {
  const { token, apiData, setApiData, setOption } = useContext(AppContext);
  const { BudgetApi } = useDashboardDataBudget(token);
  const { CommissionApi } = useDashboardDataCommission(token);
  const { TransactionApi } = useDashboardDataTransaction(token);
  const { RevenueApi } = useDashboardDataRevenue(token);
  const { ExpenseApi } = useDashboardDataExpense(token);
  const [loadingerror, setLoadingError] = useState();

  useEffect(() => {
    setApiData((prv) => ({
      ...prv,
      RevenueApi,
      CommissionApi,
      TransactionApi,
      ExpenseApi,
      BudgetApi,
    }));
  }, [RevenueApi, CommissionApi, TransactionApi, ExpenseApi, BudgetApi,setApiData]);

  const { highestStates, coordinates } = useFetchUsaMapData(apiData.RevenueApi.topHighestStateMap);
  const Options = useMapChartOptions(highestStates,coordinates,apiData.RevenueApi);

  useEffect(() => {
    if (highestStates && coordinates) {
      setOption(Options);
    }
  }, [highestStates, coordinates, setOption,Options]);


  useEffect(() => {
    if (
      apiData.RevenueApi.topHighestState === "" &&
      apiData.CommissionApi.commissionmonths === "" &&
      apiData.ExpenseApi.expensemonths === "" &&
      apiData.TransactionApi.tophigheststates === "" &&
      apiData.BudgetApi.totalbudgetactual === ""
    ) {
      setLoadingError(true);
      
    } else {
      
      setLoadingError(false);
    }
  }, [
    apiData.RevenueApi.topHighestState ,
    apiData.CommissionApi.commissionmonths ,
    apiData.ExpenseApi.expensemonths ,
    apiData.TransactionApi.tophigheststates ,
    apiData.BudgetApi.totalbudgetactual 
  ]);

  return (
    <div className="main_frame_dasboard">
      <div className="frame_menu_left" data-aos="fade-left">
        <div className="frame_logo" data-aos="fade-down" data-aos-delay="700">
          <Link to="/fathom/menupage">
            <img src={LogoMenuBar} alt="Fathom Menubar" />
          </Link>
        </div>

        <div className="frame_list_menubar">
          {menubar.map((item, index) => (
            <div key={index} className="frame_item_menu" data-aos="fade-right">
              <NavLink
                to={item.link}
                end={item.link === "/fathom/dashboards"}
                className={({ isActive }) =>
                  isActive ? "itemmenubar_active" : ""
                }
              >
                <button>{item.name}</button>
              </NavLink>
            </div>
          ))}
        </div>

        <div className="frame_note_source_data">
          <div>
            <span>Data Source on </span>
          </div>
          <div>
            <span>10/17/2024</span>
          </div>
          <div>
            <Logout />
          </div>
        </div>
      </div>
      <DrawerMode />

      <div className="main_frame_outlet">
        {loadingerror ? <Loading /> : <Outlet />}
        {/* <Outlet /> */}
      </div>
    </div>
  );
};

export default Dashboards;
