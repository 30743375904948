import React from "react";
import ReactECharts from "echarts-for-react";

const SingleColumns = ({data}) => {
  // Define your chart options
  const waitingData = data.length > 0 ? data : []

  const option = {
    legend: {},
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
    },
    dataset: {
      source: waitingData
    },
    xAxis: {
      type: "category",
      axisLine: { lineStyle: { color: "white", width: 1 } },
      axisLabel: { color: "white", fontSize: 16 },
    },
    yAxis: {
      type: "value",
      axisLine: { lineStyle: { color: "white", width: 3 } },
      axisLabel: {
        color: "white",
        fontSize: 16,
        formatter: (value) => `${(value / 1000000).toFixed(1)}M`,
      },

      splitLine: {
        show: true,
        lineStyle: {
          color: "#979798",
          type: "dashed",
          width: 1.2,
          
        },
      },
    },
    series: [
      {
        type: "bar",
        itemStyle: {
          color: "#7449B9",
          borderRadius: [10, 10, 0, 0], 
        },
        label: {
          show: true,
          position: "top",
          color: "white",
          fontSize: 16,
          formatter: (params) => {
            return `${((params.value[1] / 1000000).toFixed(2))}M`;
          },

          
          emphasis: {
            textStyle: {
              fontSize: 14,
              color: "white",
            },
            animationDuration: 1000,
            animationEasing: "cubicOut",
          },
        },
        animationDuration: 1500,
        animationEasing: "cubicOut",
        animationDelay: (idx) => idx * 100,
      },
    ],
    animationDuration: 2000,
    animationEasing: "cubicOut",
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ReactECharts option={option} style={{ height: "100%", width: "100%" }} />
    </div>
  );
};

export default SingleColumns;
