import React from "react";
import "./BudgetCard.scss";
import { AnimatedValue } from "../../utils/animationnumber/AmimationNmber";

const BudgetCard = ({
  data,
  classStyle_frametransaction,
  classStyle_titleiconvaluen,
  classStyle_title_icon,
  classStyle_value,
  delays,
  durations,
  dollar,
}) => {


  const actualexpense = {
    name: "",
    value: "",
  };

  const waitingData = data !== undefined ? data : actualexpense;


  return (
    <div
      className={`${classStyle_frametransaction}`}
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div
        className={`${classStyle_titleiconvaluen}`}
        data-aos="fade-down"
        data-aos-delay="1500"
      >
        <div className={`${classStyle_title_icon}`}>
          <span>{waitingData.name}</span>
        </div>
        <div className={`${classStyle_value}`}>
          <span>
            {dollar}
            {
              <AnimatedValue
                endValue={(waitingData.value)}
                delay={delays}
                durations={durations}
              />
            }
          </span>
        </div>
        <div className="frame_sub_contents">
          <span>{waitingData.contents}</span>
        </div>
      </div>
    </div>
  );
};

export default BudgetCard;
