import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import "antd/dist/reset.css";
import "./CardToolTip.scss";
import { AnimatedValue } from "../../utils/animationnumber/AmimationNmber";
import formatNumberWithCommas from "../../format/formatNumberWithCommas";

const DarkScreenWithTooltip = ({
  data,
  name,
  classstyles,
  delays,
  durations,
  slice,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [activeIndex,setActiveIndex] = useState(null)

  useEffect(() => {
    if (data && data.length > 0) {
      const waitingData = data.map((item) => ({
        ...item,
        elements: JSON.parse(item.elements.replace(/'/g, '"')).sort(
          (prv, curr) => curr.value - prv.value
        ),
      }));
      setParsedData(waitingData);
    }
  }, [data]);

  const handleTooltipOpenChange = (open,index) => {
    setShowOverlay(open);
   

    if (open) {
      setActiveIndex(index)
    }
    else {
      setActiveIndex(null)
    }
  };

  const [width, setWidth] = useState("");

  useEffect(() => {
    const updateWidth = () => {
      const screenWidth = window.innerWidth;
      // console.log(screenWidth);
      setWidth(screenWidth);

      

      if (screenWidth > 1200) {
        setWidth("25%");
      }
      if (800 < screenWidth < 900) {
        setWidth("70%");
      }

      if (900 < screenWidth < 1200) {
        setWidth("30%");
      }
      if(screenWidth === 834) {
        setWidth("60%");

      }

      if (screenWidth < 800) {
        setWidth("70%");
      }

      if (screenWidth === 1024) {
        setWidth("60%");
      }

      if (screenWidth === 820) {
        setWidth("60%");
      }

     

      if (screenWidth < 700) {
        setWidth("85%");
      }
    };

    updateWidth();

    // Add event listener to update width on resize
    window.addEventListener("resize", updateWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const renderTooltipContent = (item) => (
    <div className="main_frame_element_tooltip">
      <div className="frame_title">
        <span>Top highest Vendors</span>
      </div>

      <div>
        {item.elements.slice(0, slice).map((element, index) => (
          <div key={index} className="frame_element_name_value_line_tooltip">
            <div className="frame_element_name_value_tooltip">
              <div>{<span>{element.name}</span>}</div>
              <div>
                <span>
                  ${formatNumberWithCommas(parseFloat(element.value).toFixed(2))}
                </span>
              </div>
            </div>
            <div>
              <hr />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="main_frame_cardToolTip">
      <div className={`span_name ${classstyles}`}>
        <span className={`${showOverlay ? "title_active" : ""}`}>{name}</span>
      </div>
      <div className="frame_name_value_line">
        {showOverlay && <div className="dark_screen" />}
        <div className="main_map_tooltip">
          {parsedData.slice(0, slice).map((item, index) => (
            <div className="main_frame_tooltip" key={index}>
              <Tooltip
                key={index}
                title={renderTooltipContent(item)}
                placement="top"
                color="#576691"
                overlayStyle={{ color: "#fff", minWidth: width }}
                onOpenChange={(open) =>handleTooltipOpenChange(open,index)}
              >
                <>
                  <div className={`${showOverlay ? "frame_name_value_active" : "frame_name_value"}`}>
                    <div className="frame_name_item_tooltip" >
                      <span className={`${activeIndex === index ? "span_name_parent_tooltip_active" : "span_name_parent_tooltip"}`} >{item.name}</span>
                    </div>
                    <div className="frame_value_item_tooltip">
                      <span className={`${activeIndex === index ? "span_value_parent_tooltip_active" : "span_value_parent_tooltip"}`} >
                        {
                          <AnimatedValue
                            endValue={item.value}
                            delay={delays}
                            durations={durations}
                            dollar="$"
                          />
                        }
                      </span>
                    </div>
                  </div>
                  <div className="frame_hr_carttooltip">
                    <hr />
                  </div>
                </>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DarkScreenWithTooltip;
