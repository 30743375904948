import React, { useRef } from "react";
import ReactECharts from "echarts-for-react";

const LineChart = ({ data,classstyles, tooltips,shows,types }) => {
  
  
  const chartRef = useRef(null);
  const months = data ? data.map(item => item.month): [];
  const values = data ? data.map(item => item.value): [];
  

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
      position: function (point, params, dom, rect, size) {
        var x = point[0];
        var y = point[1];
        var boxWidth = size.contentSize[0];

        if (x + boxWidth + 30 > size.viewSize[0]) {
          x -= boxWidth + 30;
        } else {
          x -= 75;
        }

        return [x, y];
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: months,
      axisLine: { lineStyle: { color: "white", width: 4 } },
      axisLabel: { color: "white", fontSize: 14 },
    },
    yAxis: {
      type: "value",
      axisLine: { lineStyle: { color: "white" } },
      axisLabel: { color: "white", fontSize: 14,
        formatter: (value) => {
          return value >= 1000000 
            ? `${(value / 1000000).toFixed(0)}M` 
            : value.toLocaleString(); 
        }
        
       },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#ccc",
          type: "dashed",
          width: 1.2,
        },
      },
    },
    series: [
      {
        data: values,
        type: "line",
        lineStyle: { color: "#fff", width: 4 },
        areaStyle: { color: "#626DA6" },
        symbol: "none",
        
        markPoint: {
          data: [
            { type: types,
              show:shows,
              
              name: "Highest", itemStyle: { color: "white" } },
          ],
          label: {
            fontSize: 12,
            color: "black",
            formatter: (params) => {
              const value = params.value;
              if (value >= 1000000) {
                return `${Math.floor(value / 1000000)}M`; 
              }
              return value; 
            },
          },
          symbol: tooltips || "none",
          symbolSize: 60,
          animation: true,
          animationDuration: 1500,
          animationEasing: 'bounceOut', // Bounce effect
          animationDelay: (idx) => idx * 300, // Delay for each point
          animationDurationUpdate: 1000,
          animationEasingUpdate: 'bounceOut'
        },
      },
    ],
    backgroundColor: "transparent",
  };

  return (
    <div className={`${classstyles}`}>
      <ReactECharts
        ref={chartRef}
        option={option}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default LineChart;


// import React, { useRef, useEffect, useState } from "react";
// import ReactECharts from "echarts-for-react";

// const LineChart = ({ data, classstyles, tooltips, shows, types }) => {
//   const chartRef = useRef(null);
//   const [fontSize, setFontSize] = useState(17); // Default font size

//   const months = data ? data.map((item) => item.month) : [];
//   const values = data ? data.map((item) => item.value) : [];

//   // Function to adjust the font size based on chart width
//   const adjustFontSize = () => {
//     if (chartRef.current) {
//       const chart = chartRef.current.getEchartsInstance();
//       const width = chart.getWidth();
//       const height = chart.getHeight();

//       // Dynamically set font size based on chart width or height
//       const newFontSize = Math.max(12, Math.min(width / 25, height / 25));
//       setFontSize(newFontSize);
//     }
//   };

//   // Adjust the font size when the component mounts or when the window resizes
//   useEffect(() => {
//     adjustFontSize();
//     window.addEventListener("resize", adjustFontSize);

//     return () => {
//       window.removeEventListener("resize", adjustFontSize);
//     };
//   }, []);

//   const option = {
//     tooltip: {
//       trigger: "axis",
//       axisPointer: {
//         type: "line",
//       },
//       position: function (point, params, dom, rect, size) {
//         var x = point[0];
//         var y = point[1];
//         var boxWidth = size.contentSize[0];

//         if (x + boxWidth + 30 > size.viewSize[0]) {
//           x -= boxWidth + 30;
//         } else {
//           x -= 75;
//         }

//         return [x, y];
//       },
//     },
//     xAxis: {
//       type: "category",
//       boundaryGap: false,
//       data: months,
//       axisLine: { lineStyle: { color: "white", width: 4 } },
//       axisLabel: { color: "white", fontSize: fontSize }, // Dynamic font size
//     },
//     yAxis: {
//       type: "value",
//       axisLine: { lineStyle: { color: "white" } },
//       axisLabel: {
//         color: "white",
//         fontSize: fontSize, // Dynamic font size
//         formatter: (value) => {
//           return value >= 1000000
//             ? `${(value / 1000000).toFixed(0)}M`
//             : value.toLocaleString();
//         },
//       },
//       splitLine: {
//         show: true,
//         lineStyle: {
//           color: "#ccc",
//           type: "dashed",
//           width: 1.2,
//         },
//       },
//     },
//     series: [
//       {
//         data: values,
//         type: "line",
//         lineStyle: { color: "#fff", width: 4 },
//         areaStyle: { color: "#626DA6" },
//         symbol: "none",

//         markPoint: {
//           data: [
//             {
//               type: types,
//               show: shows,
//               name: "Highest",
//               itemStyle: { color: "white" },
//             },
//           ],
//           label: {
//             fontSize: fontSize, // Dynamic font size
//             color: "black",
//             formatter: (params) => {
//               const value = params.value;
//               if (value >= 1000000) {
//                 return `${Math.floor(value / 1000000)}M`;
//               }
//               return value;
//             },
//           },
//           symbol: tooltips || "none",
//           symbolSize: 60,
//           animation: true,
//           animationDuration: 1500,
//           animationEasing: "bounceOut", // Bounce effect
//           animationDelay: (idx) => idx * 300, // Delay for each point
//           animationDurationUpdate: 1000,
//           animationEasingUpdate: "bounceOut",
//         },
//       },
//     ],
//     backgroundColor: "transparent",
//   };

//   return (
//     <div className={`default ${classstyles}`}>
//       <ReactECharts
//         ref={chartRef}
//         option={option}
//         style={{ width: "100%", height: "100%" }}
//       />
//     </div>
//   );
// };

// export default LineChart;
