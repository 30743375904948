import React, { useState } from "react";
import { Drawer, FloatButton } from "antd";
import { Link, NavLink } from "react-router-dom";
import LogoMenuBar from "../../assets/menubar/new_logo_menubar.png";
import menubar from "../../data/menubar";
import "./Drawer.scss";
import Logout from "../../pages/authentification_page/logout/Logout";
import { CloseOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const DrawerMode = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <FloatButton
        onClick={showDrawer}
        placement="left"
        icon={<MenuUnfoldOutlined style={{ color: "#132437" }} />}
        style={{ insetInlineStart: 24 }}
        className="floadbutton"
      />

      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="frame_drawermode"
        closeIcon={
          <CloseOutlined style={{ color: "white", fontSize: "18px" }} />
        }
      >
        <div className="frame_menu_left">
          <div className="frame_logo">
            <Link to="/fathom/menupage">
              <img src={LogoMenuBar} alt="Fathom Menubar" />
            </Link>
          </div>

          <div className="frame_list_menubar">
            {menubar.map((item, index) => (
              <div key={index} className="frame_item_menu" onClick={onClose}>
                <NavLink
                  to={item.link}
                  end={item.link === "/fathom/dashboards"}
                  className={({ isActive }) =>
                    isActive ? "itemmenubar_active" : ""
                  }
                >
                  <button>{item.name}</button>
                </NavLink>
              </div>
            ))}
          </div>

          <div className="frame_note_source_data">
            <div>
              <div>
                <span>Data Source on </span>
              </div>
              <div>
                <span>10/17/2024</span>
              </div>
              <div>
                <Logout />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default DrawerMode;
