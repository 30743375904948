import React, { useState, useEffect } from "react";
import formatNumberWithCommas from "../../format/formatNumberWithCommas";

export const AnimatedValue = ({ endValue, delay, durations, span_style, dollar }) => {
  const [value, setValue] = useState(0);
  const [peakValue, setPeakValue] = useState(0);

  useEffect(() => {
    const duration = durations;
    const peakDuration = duration / 2;
    const startTime = performance.now();
    const frameDuration = 2000 / 40; // 60 frames per second for smoother animation

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      let progress;

      if (elapsedTime < peakDuration) {
        // First half: go from 0 to peak value
        progress = Math.min(elapsedTime / peakDuration, 1);
        const peak = endValue * 1.1; // Peak value is a bit higher than endValue
        setPeakValue(Math.floor(progress * peak));
        setValue(peakValue);
      } else if (elapsedTime < duration) {
        // Second half: go from peak value to end value
        progress = Math.min((elapsedTime - peakDuration) / peakDuration, 1);
        setValue(Math.floor((1 - progress) * peakValue + progress * endValue));
      } else {
        setValue(endValue); // Ensure it ends at the target value
      }

      if (elapsedTime < duration) {
        setTimeout(() => requestAnimationFrame(animate), frameDuration); // Delay each frame for smoother animation
      }
    };

    // Delay the animation
    const startAnimation = () => {
      requestAnimationFrame(animate);
    };

    const timer = setTimeout(startAnimation, delay);

    // Cleanup on component unmount or delay change
    return () => {
      clearTimeout(timer);
    };
  }, [endValue, delay, peakValue, durations]);

  return <span className={`${span_style}`}>{dollar}{formatNumberWithCommas(value)}</span>;
};
