import React from "react";
import "./TMCard.scss";
import { AnimatedValue } from "../../utils/animationnumber/AmimationNmber";

const TMCard = ({ data, title, delays, durations }) => {
  const waitingData = data.length > 0 ? data : [{}];

  return (
    <div
      className="frame_transaction_card"
      data-aos="flip-right"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="2000"
    >
      <div className="frame_children_elment_TM_Card">
        <div className="frame_tile_name_total">
          <div
            className="frame_title"
            data-aos="fade-right"
            data-aos-delay="1000"
          >
            <span>{title}</span>
          </div>
          <div className="main_frame_name_total">
            <div className="frame_name_total">
              {waitingData.slice(0, 5).map((item, index) => (
                <div
                  key={index}
                  className="frame_span_element"
                  data-aos="fade-up"
                  data-aos-delay="2000"
                >
                  <span>{item.name}</span>
                  <span>
                    {
                      <AnimatedValue
                        endValue={parseInt(item.value)}
                        delay={delays}
                        durations={durations}
                      />
                    }
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TMCard;
