// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame_logout_icon {
  color: white;
  font-size: 1.1rem;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  margin-top: 5%;
}
.frame_logout_icon .icon_logout {
  transform: rotate(180deg);
  margin-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/authentification_page/logout/Logout.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAEA;EAEI,YAAA;EACA,iBAAA;EACA,+BCFW;EDGX,eAAA;EAEA,cAAA;AAHJ;AAMI;EACI,yBAAA;EACA,oBAAA;AAJR","sourcesContent":["@use '../../../scss/' as *;\r\n\r\n.frame_logout_icon {\r\n\r\n    color:  white;\r\n    font-size: 1.1rem;\r\n    font-family: $global-font;\r\n    cursor: pointer;\r\n\r\n    margin-top: 5%;\r\n    // background-color: red;\r\n\r\n    .icon_logout {\r\n        transform: rotate(180deg);\r\n        margin-right: 0.5rem;\r\n        // font-size: 1.2rem;\r\n    }\r\n}","$mobile-width:600px;\r\n$tablet-width: 1024px;\r\n$desktop-width:4000px;\r\n$rotate-width: 950px;\r\n$global-font:  \"Lato\", sans-serif;\r\n$shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;\r\n$shadow2:rgba(0, 0, 0, 0.24) 0px 3px 8px;\r\n$color-button-linear: linear-gradient(90deg, #511013 0%, #9B191E 100%);\r\n$color-button-linear-hover: linear-gradient(100deg, #CD171F 0%, #670C10 100%);\r\n$color-data-time:#50618E"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
