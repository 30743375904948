import React from "react";
import "./MenuPage.scss";
import FathomLogo from "../../assets/menupage/Fathom-Logo.png";
import menubar from "../../data/menubar";
import { Link } from "react-router-dom";

const MenuPage = () => {
  return (
    <div className="main_frame_menuPage" data-aos="fade-down-right">
      <div className="left_frame" data-aos="fade-up">
        <Link >
          <img src={FathomLogo} alt="Fathom Logo" />
        </Link>
      </div>

      <div className="right_frame">
        <div className="children_right_frame">
          <div className="frame-title" data-aos="fade-down">
            <h2>Choose the dashboard</h2>
          </div>

          <div className="frame_menu" data-aos="fade-left">
            {menubar.map((item,index) => (
              <Link to={item.link} key={index} >
                <div className="frame_button">
                  <button>
                    <span>{item.name}</span>
                  </button>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuPage;
