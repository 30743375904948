import React from "react";
import { caculatePercent } from "../../../utils/percentage/CaculatePercent";
import BarItem from "./baritem/BarItem";
import "./CustomLineChart.scss";

const CustomLineChart = ({ title, classStyles_title, delays, durations, span_style, data }) => {
  const waitingData = data.length > 0 ? caculatePercent(data) : [];

  return (
    <div className="frame_custom_Line_Chart" >
      <div className={`${classStyles_title}`}>
        <span>{title}</span>
      </div>

      <div className="frame_parent_baritem_chart">
        {waitingData.slice(0, 5).map((item, index) => (
          <div className="frame_custom_barItem" key={index}>
            <BarItem
              key={index}
              name={item.name}
              value={item.value}
              percentage={item.percentage}
              delays={delays}
              durations={durations}
              span_style={span_style}

            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomLineChart;
