const colorPalette = [
    "#D0D6E2", 
    "#B0B9D6",
    "#7C8AB6",
    "#9DA8D1", 
    "#6C7493", 
    "#6C7B9B", 
    "#4A4F6E", 
    "#505568", 
    "#5F637E", 
    "#6A6F8E", 
    "#6C7493", 
    "#3F4058"  
  ];

  export default colorPalette