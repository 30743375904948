import { useEffect, useState } from "react";
import {
    fetchTotalExpense,
    fetchHighestStates,
    fetchExpenseMonths,
    fetchExpenseRegions,
    fetchHighestMarkets,
    fetchHighestCategories,
    fetchHighestVendor,
 
} from "./getApi.js";

const useDashboardData = (token) => {
  const [ExpenseApi, setExpenseApi] = useState({
    totalexpense: "",
    higheststates: "",
    expensemonths: "",
    expenseregions: "",
    highestmarkets: "",
    highestcategories: "",
    highestvendors: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
        totalexpenseRes,
        higheststatesRes,
        expensemonthsRes,
        expenseregionsRes,
        highestmarketsRes,
        highestcategoriesRes,
        highestvendorsRes,
      
        ] = await Promise.all([
            fetchTotalExpense(token),
            fetchHighestStates(token),
            fetchExpenseMonths(token),
            fetchExpenseRegions(token),
            fetchHighestMarkets(token),
            fetchHighestCategories(token),
            fetchHighestVendor(token)

          
        ]);

        setExpenseApi({
            totalexpense: totalexpenseRes.data, // access data from axios
            higheststates: higheststatesRes.data,
            expensemonths: expensemonthsRes.data,
            expenseregions: expenseregionsRes.data,
            highestmarkets: highestmarketsRes.data,
            highestcategories: highestcategoriesRes.data,
            highestvendors: highestvendorsRes.data,
         
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return { ExpenseApi, loading, error };
};

export default useDashboardData;
