import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import dataGeomap from "../../../data/USA_DATA.json";
import { Flex, Spin } from "antd";
import formatNumberWithCommas from "../../../format/formatNumberWithCommas";
import colorPalette from "../../../utils/colorTemplete/ColorTemplate";

const GeoMap = ({data}) => {
  
  
  const usaJson = dataGeomap;

  // Register the map
  echarts.registerMap("USA", usaJson, {
    Alaska: { left: -131, top: 25, width: 15 },
    Hawaii: { left: -110, top: 28, width: 5 },
    "Puerto Rico": { left: -76, top: 26, width: 2 },
  });

  // Set chart options
  const chartOption = {
    tooltip: {
      trigger: "item",
      showDelay: 0,
      transitionDuration: 0.2,
      formatter: function (params) {
        return `${params.name}: ${formatNumberWithCommas(params.value) !== "NaN" ? "$" + formatNumberWithCommas(params.value) : "$0"  }`;
      },
    },
    visualMap: {
      left: "right",
      top: "48.5%",
      min: 500000,
      max: 38000000,
      inRange: {
        color: colorPalette,
      },
      text: ["High", "Low"],
      calculable: true,
    },
    toolbox: {
      show: true,
      left: "left",
      top: "top",
    },
    series: [
      {
        name: "Agent Commission",
        type: "map",
        roam: false,
        map: "USA",
        zoom: 1.2,
        emphasis: {
          itemStyle: {
            areaColor: "white",
            borderColor: "gray",
            borderWidth: 1,
          },
          label: { show: true, color: "black" },
        },
        data: data,
      },
    ],
  };

  return (
    <div className="frame_parent_customusamap">
      {data ? (
        <ReactECharts
          option={chartOption}
          style={{ height: "100%", width: "100%" }}
        />
      ) : (
        <div className="frame_loading">
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </div>
      )}
    </div>
  );
};

export default GeoMap;
