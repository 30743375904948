import React from "react";
import ReactECharts from "echarts-for-react";
import './ColumnsChart.scss'

const ColumnsChart = ({data}) => {
  // Define your chart options
  const waitingData = data.length > 0 ? data : []

  const option = {
    legend: {
      data: ['Total Budget', 'Total Actual Expenses'],
      textStyle: {
        color: 'white', 
        fontSize: 14,
        padding: [10, 20, 10, 0], 
      },
      padding: [10, 20, 20, 20], 
      itemGap: 80, 
      // itemWidth: 20, // Set width of the legend items
      // itemHeight: 20, // Set height of the legend items
  
    
    },
    
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
      backgroundColor: "rgba(50, 50, 50, 1)",
  textStyle: {
    color: "#fff", 
  },
  borderColor: "#333", 
  borderWidth: 1,
  padding: 10,
      formatter: (params) => {
        let result = `${params[0].axisValue}<br/>`; 
        params.forEach((item) => {
          let formattedValue = parseFloat(item.value[item.seriesIndex + 1]).toLocaleString();
          result += `${item.marker} ${formattedValue}<br/>`;
        });
        return result;
    },
    },
    dataset: {
      source: waitingData
    },
    xAxis: {
      type: "category",
      axisLine: { lineStyle: { color: "white", width: 1 } },
      axisLabel: { color: "white", fontSize: 14 }, 
    },
    yAxis: {
      type: "value",
      axisLine: { lineStyle: { color: "white", width: 3 } },
      axisLabel: {
        color: "white",
        fontSize: 15,
        formatter: (value) => `${(value / 1000000).toFixed(1)}M`,
      },

      splitLine: {
        show: true,
        lineStyle: {
          color: "#979798",
          type: "dashed",
          width: 1.2,
        },
      },
    },
    series: [
      {
        type: "bar",
        itemStyle: {
          color: "rgb(252, 249, 244)",
          borderRadius: [10, 10, 0, 0], 
        },
        label: {
          show: true, 
          position: "top",
          color: "white",
          fontSize: 12, 
          formatter: (params) => {
            return `${(params.value[1] / 1000000).toFixed(1)}M`;
          },
          emphasis: {
            textStyle: {
              fontSize: 14,
              color: "white", 
            },
            animationDuration: 1000, 
            animationEasing: "cubicOut", 
          },
        },
        animationDuration: 1500, 
        animationEasing: "cubicOut", 
        animationDelay: (idx) => idx * 100, 
      },
      {
        type: "bar",
        itemStyle: {
          color: "#839AD9",
          borderRadius: [10, 10, 0, 0], 
        },
        label: {
          show: true, 
          position: "top",
          color: "white",
          fontSize: 12, 
          formatter: (params) => {
            return `${(params.value[2] / 1000000).toFixed(1)}M`; 
          },
          emphasis: {
            textStyle: {
              fontSize: 14, 
              color: "white", 
            },
            animationDuration: 1000, 
            animationEasing: "cubicOut", 
          },
        },
        animationDuration: 1500, 
        animationEasing: "cubicOut", 
        animationDelay: (idx) => idx * 100, 
      },
    ],
    animationDuration: 2000, 
    animationEasing: "cubicOut", 
  };

  return (
    <div className="columns_chart_budget">
      <ReactECharts option={option} style={{ height: "100%", width: "100%" }} />
    </div>
  );
};

export default ColumnsChart;
