import { getTooltipContent } from '../../../utils/formaterpiechart/FormaterPieChart';
import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import './PieChart.scss';

const PieChart = ({data}) => {
  const [darkScreenVisible, setDarkScreenVisible] = useState(false);
  const [parsedData, setParsedData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const waitingData = data.map((item) => ({
        ...item,
        value: parseFloat(item.value),
        elements: JSON.parse(item.elements.replace(/'/g, '"')).sort(
          (prv, curr) => curr.value - prv.value
        ),
      }));
      setParsedData(waitingData);
    }
  }, [data]);



  useEffect(() => {
    const chartDom = document.getElementById('main');
    const myChart = echarts.init(chartDom);
    const windowWidth = window.innerWidth; 
    const option = {
    
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
            setDarkScreenVisible(true);
            return getTooltipContent(params, parsedData);
        },
        backgroundColor: "rgba(95, 97, 118, 0.99)",
        borderColor: '#fff',
        borderWidth: 1,
        padding: 10,
        position: function (point, params, dom, rect, size) {
            // Get window width
            let x, y;
    
            // Positioning logic for mobile devices
            if (windowWidth < 700) {
                // Center the tooltip when window width < 768
                x = (windowWidth - size.contentSize[0] ) / 2; 
                y = (windowWidth - size.contentSize[0] - 60) / 2; 
            }
            // Positioning logic for small screens between 768 and 800
            else if (windowWidth < 940) {
                x = point[0] - 160;
                y = point[1] - size.contentSize[1] + 10; 
            }
            // Positioning logic for larger screens
            else {
                x = point[0] - 40; 
                y = point[1] - size.contentSize[1] - 10;
            }
    
            return [x, y]; 
        },
        textStyle: {
            color: 'white',
        },
    },
    
      legend: {
        //   top: '5%',
        bottom: "5%",
        left: "center",
        //   padding: [-20, 0],
        padding: [-10, 0],
        
        itemGap: windowWidth <768 ? 10 : 50,
        //   itemGap: 60,
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
          color: "white",
        },
      },
      series: [
        {
          name: "total",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: data
        },
      ],
    };

    

    myChart.setOption(option);

    myChart.on('mouseover', (params) => {
      if (params.componentType !== 'series') {
        setDarkScreenVisible(false);
      }
    });

    myChart.on('mouseout', () => {
      setDarkScreenVisible(false);
    });

    const handleResize = () => {
      myChart.resize();
    };
  
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      myChart.dispose();
    };
  }, [data,parsedData]);

  return (
    <div className='main_frame_piechart_expense'>
      {darkScreenVisible && <div id="darkScreen" className="dark-screen active"></div>}
      <div id="main" className='frame_parent_piechart_expense'></div>
    </div>
  );
};

export default PieChart;




