import React from "react";
import "./BudgetPage.scss";
import { AppContext } from "../../App";
import { useContext } from "react";
import BudgetCard from "../../components/budgetCard/BudgetCard";
import ColumnsChart from "../../components/chart/columnschart/ColumnsChart";
import DataTime from "../../components/datatime/DataTime";
import { getApiData } from "../../utils/getdata/getApiData";

const BudgetPage = () => {
  const { apiData } = useContext(AppContext);

  const dataBudgetCard = getApiData(apiData, "BudgetApi", "totalbudgetcard");
  const BudgetCard1 = dataBudgetCard?.[0] || "";
  const BudgetCard2 = dataBudgetCard?.[1] || "";
  const BudgetCard3 = dataBudgetCard?.[2] || "";
  const BudgetCard4 = dataBudgetCard?.[3] || "";

  return (
    <div className="main_frame_budget">
      <DataTime />
      <div className="frame_top_card_bugdet">
        <BudgetCard
          data={BudgetCard1}
          classStyle_frametransaction="frame_total_budget"
          classStyle_titleiconvaluen="frame_title_icon_value"
          classStyle_title_icon="frame_title_icon"
          classStyle_value="frame_value"
          delays={3000}
          durations={3600}
          dollar="$"
        />
        <BudgetCard
          data={BudgetCard2}
          classStyle_frametransaction="frame_total_budget"
          classStyle_titleiconvaluen="frame_title_icon_value"
          classStyle_title_icon="frame_title_icon"
          classStyle_value="frame_value"
          delays={3000}
          durations={3600}
          dollar="$"
        />
        <BudgetCard
          data={BudgetCard3}
          classStyle_frametransaction="frame_total_budget"
          classStyle_titleiconvaluen="frame_title_icon_value"
          classStyle_title_icon="frame_title_icon"
          classStyle_value="frame_value"
          delays={3000}
          durations={3600}
          dollar="$"
        />
        <BudgetCard
          data={BudgetCard4}
          classStyle_frametransaction="frame_total_budget"
          classStyle_titleiconvaluen="frame_title_icon_value"
          classStyle_title_icon="frame_title_icon"
          classStyle_value="frame_value"
          delays={3000}
          durations={3600}
          dollar="$"
        />
      </div>
      <div
        className="frame_budgetbymonht"
        data-aos="fade-up-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        <div className="frame_title_chart_budgets">
          <span>BUGDET AND ACTUAL EXPENSES BY MONTHS</span>
        </div>

        <div className="frame_colums_chart">
          <ColumnsChart
            data={getApiData(apiData, "BudgetApi", "totalbudgetactual")}
          />
        </div>
      </div>
    </div>
  );
};

export default BudgetPage;
