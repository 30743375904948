import React, { useState, useEffect } from "react";
import "./BarItemTooltip.scss";
import { Tooltip } from "antd";
import formatNumberWithCommas from "../../../../format/formatNumberWithCommas";
import { AnimatedValue } from "../../../../utils/animationnumber/AmimationNmber";
import { caculatePercentFormatdata } from "../../../../utils/percentage/CaculatePercent";

const BarItemHorizontal = ({
  name,
  value,
  percentage,
  delays,
  durations,
  span_style,
  elements,
  index,
  style_baritem_index,
  style_baritem_index_active,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
 

  const itemStyles = {
    height: `${percentage}%`,
  };

  const itemStylesmargin = {
    marginTop: `-${percentage}px`,
  };

  const validJsonString = elements.replace(/'/g, '"');
  const elementsMap = JSON.parse(validJsonString);

  const [width, setWidth] = useState("");

  useEffect(() => {
    const updateWidth = () => {
      const screenWidth = window.innerWidth;
      // console.log(screenWidth);
      setWidth(screenWidth);

      if (screenWidth > 1200) {
        setWidth("25%");
      }
      if (800 < screenWidth < 900) {
        setWidth("70%");
      }
      

      if (900 < screenWidth < 1200) {
        setWidth("30%");
      }
      if (screenWidth === 834) {
        setWidth("60%");
      }

      if (screenWidth < 800) {
        setWidth("50%");
      }

      if (screenWidth === 1024) {
        setWidth("40%");
      }

      if (screenWidth === 820) {
        setWidth("55%");
      }

      if (screenWidth < 700) {
        setWidth("50%");
      }
    };

    updateWidth();

    // Add event listener to update width on resize
    window.addEventListener("resize", updateWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const handleTooltipOpenChange = (open) => {
    setShowOverlay(open);
  };

  const renderTooltipContent = (item) => {
    const newdata = caculatePercentFormatdata(item);

    const slice = newdata.length > 5 ? 5 : newdata.length;

    return (
      <div className="main_frame_element_tooltip_new">
        <div className="frame_title">
          <span>{`Top ${slice} Vendors`}</span>
        </div>
        <div className="frame_parent_name_value_line_bar_new">
          {newdata.slice(0, slice).map((element, index) => (
            <div key={index} className="frame_element_name_value_line_tooltip">
              <div className="frame_element_name_value_tooltip">
                <div>{<span>{element.name}</span>}</div>
                <div>
                  <span>
                    {parseFloat(element.value).toFixed(2) >= 0
                      ? `$${formatNumberWithCommas(
                          parseFloat(element.value).toFixed(2)
                        )}`
                      : `($${formatNumberWithCommas(
                          Math.abs(parseFloat(element.value).toFixed(2))
                        )})`}
                  </span>
                </div>
              </div>
              <div className="frame_parent_line_color_new">
                <div
                  style={{ width: `${element.percentage}%` }}
                  className="line_color_baritem_new"
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="main_frame_barItem_tooltip">
      {showOverlay && <div className="dark-overlay" />}
      <div className="frame_parent_div_tooltip">
        <div className="frame_top_line_tooltip">
          <div className="frame_line_percent_color">
            <span className="span_none_active" style={{...itemStylesmargin}}>
              <AnimatedValue
                endValue={value}
                delay={delays}
                durations={durations}
                span_style={span_style}
                dollar="$"
              />
            </span>
            <Tooltip
              color="white"
              placement={width === "50%" ? "top" : "right"}
              overlayStyle={{ color: "#fff", minWidth: width }}
              overlayInnerStyle={{ padding: 0, borderRadius: "20px" }}
              title={renderTooltipContent(elementsMap)}
              onOpenChange={(open) => handleTooltipOpenChange(open)}
            >
              <div
                style={{ ...itemStyles }}
                className={`${
                  showOverlay ? style_baritem_index_active : style_baritem_index
                }`}
              ></div>
            </Tooltip>
          </div>
        </div>
        <div className="frame_bottom_line_tooltip">
          <span>{name}</span>
        </div>
      </div>
    </div>
  );
};

export default BarItemHorizontal;
