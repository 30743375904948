import React from "react";
import "./BarItem.scss";

import { AnimatedValue } from "../../../../utils/animationnumber/AmimationNmber";
const BarItem = ({ name, value, percentage, delays, durations, span_style }) => {
  const itemStyles = {
    width: `${percentage}%`,
  };

  return (
    <div className="main_frame_barItem">
      <div className="frame_categories_value">
        <div className="frame_categories">
          <span>{name}</span>
        </div>
        <div className="frame_value">
          <span>{<AnimatedValue endValue={value} delay={delays} durations={durations} span_style={span_style} dollar="$" />}</span>
        </div>
      </div>
      <div style={{ ...itemStyles }} className="frame_line_percentage" />
    </div>
  );
};

export default BarItem;
