import React from "react";
import "./ChartToolTip.scss";
import { caulatePercentByXvalue } from "../../../utils/percentage/CaculatePercent";
import BarItemVertical from "../charttooltip/baritem/BarItemTooltip";

const ChartToolTip = ({ title, data , style_baritem_index,style_baritem_index_active  }) => {
  const waitingData = data.length > 0 ? data : [];
  const getMaxValue = Math.max(...waitingData.map((item) => item.value));
  const getValueX = Math.ceil(parseFloat(getMaxValue / 1000000).toFixed(2));
  const roundUp = Math.ceil(getMaxValue / 1000000) * 1000000;
  const upDatePercent = caulatePercentByXvalue(waitingData, roundUp);



  const dataX = [];
  for (let i = getValueX; i > 0; i--) {
    dataX.push({ name: `$${i}M` });
  }

  return (
    <div className="frame_vertical_chart_tooltip">
      <div className="frame_title_vertical_chart_tooltip">
        <span>{title}</span>
      </div>

      <div className="frame_line_left_column_right">
        <div className="frame_line_left_M">
          <div className="top_left_M">
            {dataX.map((item, index) => (
              <div key={index} className="value_M">
                <span>{item.name}</span>
              </div>
            ))}
           
          </div>

          <div className="bottom_left_M">
          <span>$0M</span>
          </div>
        </div>

        <div className="frame_column_right_value">
          <div className="frame_main_chart_vertical_tooltip">
            {upDatePercent.slice(0, 5).map((item, index) => (
              <div className="frame_custom_barItem_tooltip" key={index}>
                <BarItemVertical
                  key={index}
                  name={item.name}
                  value={item.value}
                  elements={item.elements}
                  percentage={item.percentage}
                  MaxValue={getMaxValue}
                  index={index}
                  style_baritem_index = {style_baritem_index}
                  style_baritem_index_active = {style_baritem_index_active} 
                />
              </div>
            ))}
          </div>
          <div className="dashed_line_map">
            {dataX.map((item,index) => (
              <div key={index} className="frame_each_dashed_line" >
                
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartToolTip;
