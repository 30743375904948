import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const useAos = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
      offset: 0,
    });
  }, []);
};

export default useAos;
