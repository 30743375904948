import { useState, useEffect } from "react";
import createChartOptions from "./optionusamap";

const useMapChartOptions = (highestStates, coordinates, revenueApi) => {
  const [option, setOption] = useState(null);

  useEffect(() => {
    if (highestStates.length > 0 && Object.keys(coordinates).length > 0) {
      const chartOption = createChartOptions(highestStates, coordinates, revenueApi);
      setOption(chartOption);
    }
  }, [highestStates, coordinates, revenueApi]);

  return option;
};

export default useMapChartOptions;
