// src/api.js
import axios from "axios";
import { URL } from "../../constant/url";


const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const createApiWithToken = (token) => {
  const requestToken = token || getTokenFromLocalStorage();
  const headers = {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  };

  if (requestToken) {
    headers["Authorization"] = `${requestToken}`;
  }

  return axios.create({
    baseURL: `${URL}/api/budget`,
    headers: headers,
  });
};

export const fetchTotalBudgetCard = (token) => {
  const api = createApiWithToken(token);
  return api.get("/totalbudgets");
};
export const fetchTotalBudgetActual = (token) => {
  const api = createApiWithToken(token);
  return api.get("/totalbudgetandactual");
};
