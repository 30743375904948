// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_frame_barItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main_frame_barItem span {
  font-family: "Lato", sans-serif;
}
.main_frame_barItem .frame_categories_value {
  display: flex;
  justify-content: space-between;
}
.main_frame_barItem .frame_categories_value .frame_categories {
  padding-bottom: 3px;
}

.frame_line_percentage {
  background-color: white;
  height: 15px;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/chart/customlinechart/baritem/BarItem.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;AADF;AAGE;EACE,+BCJW;ADGf;AAIE;EACE,aAAA;EACA,8BAAA;AAFJ;AAII;EACE,mBAAA;AAFN;;AAOA;EACE,uBAAA;EACA,YAAA;EACA,mBAAA;AAJF","sourcesContent":["@use \"../../../../scss/\" as *;\r\n\r\n.main_frame_barItem {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n\r\n  span {\r\n    font-family: $global-font;\r\n  }\r\n\r\n  .frame_categories_value {\r\n    display: flex;\r\n    justify-content: space-between;\r\n\r\n    .frame_categories {\r\n      padding-bottom: 3px;\r\n    }\r\n  }\r\n}\r\n\r\n.frame_line_percentage {\r\n  background-color: white;\r\n  height: 15px;\r\n  border-radius: 10px;\r\n}\r\n","$mobile-width:600px;\r\n$tablet-width: 1024px;\r\n$desktop-width:4000px;\r\n$rotate-width: 950px;\r\n$global-font:  \"Lato\", sans-serif;\r\n$shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;\r\n$shadow2:rgba(0, 0, 0, 0.24) 0px 3px 8px;\r\n$color-button-linear: linear-gradient(90deg, #511013 0%, #9B191E 100%);\r\n$color-button-linear-hover: linear-gradient(100deg, #CD171F 0%, #670C10 100%);\r\n$color-data-time:#50618E"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
