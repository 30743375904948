import { useEffect, useState } from "react";
import {
  fetchTotalTransaction,
  fetchTopHighestSates,
  fetchTopHighestMarkets,
  fetchTotalTransactionMonht,
} from "./getApi.js";

const useDashboardData = (token) => {
  const [TransactionApi, setTransactionApi] = useState({
    totaltransaction: "",
    tophigheststates: "",
    tophighestmarkets: "",
    totaltransactionmonth: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          totaltransactionRes,
          tophigheststatesRes,
          tophighestmarketsRes,
          totaltransactionmonthRes,
        ] = await Promise.all([
          fetchTotalTransaction(token),
          fetchTopHighestSates(token),
          fetchTopHighestMarkets(token),
          fetchTotalTransactionMonht(token),
        ]);

        setTransactionApi({
          totaltransaction: totaltransactionRes.data, // access data from axios
          tophigheststates: tophigheststatesRes.data,
          tophighestmarkets: tophighestmarketsRes.data,
          totaltransactionmonth: totaltransactionmonthRes.data,
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return { TransactionApi, loading, error };
};

export default useDashboardData;
