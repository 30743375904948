// // src/api.js
// import axios from "axios";
// import { URL } from "../../constant/url";

// const getTokenFromLocalStorage = () => {
//   return localStorage.getItem("token");
// };

// const createApiWithToken = (token) => {
//   const requestToken = token || getTokenFromLocalStorage();

//   return axios.create({
//     baseURL: `${URL}/api/revenue`,
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `${requestToken}`,
//     },
//   });
// };

// export const fetchTopRevenueCard = (token) => {
//   const api = createApiWithToken(token);
//   return api.get("/toprevenuecard");
// };

// export const fetchTopHighestStateMap = (token) => {
//   const api = createApiWithToken(token);
//   return api.get("/tophigheststatemap");
// };

// export const fetchTotalRevenueMonths = (token) => {
//   const api = createApiWithToken(token);
//   return api.get("/totalrevenuemonths");
// };

// export const fetchTotalRevenueRegions = (token) => {
//   const api = createApiWithToken(token);
//   return api.get("/totalrevenueregions");
// };

// export const fetchTopHighestCategories = (token) => {
//   const api = createApiWithToken(token);
//   return api.get("/tophighestcategories");
// };

// export const fetchTopHighestState = (token) => {
//   const api = createApiWithToken(token);
//   return api.get("/tophigheststate");
// };

// export const fetchTopHighestMarkets = (token) => {
//   const api = createApiWithToken(token);
//   return api.get("/tophighestmarkets");
// };

import axios from "axios";
import { URL } from "../../constant/url";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const createApiWithToken = (token) => {
  const requestToken = token || getTokenFromLocalStorage();
  const headers = {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  };

  if (requestToken) {
    headers["Authorization"] = `Bearer ${requestToken}`;
  }

  return axios.create({
    baseURL: `${URL}/api/revenue`,
    headers: headers,
  });
}; // <-- Added the missing closing brace

export const fetchTopRevenueCard = (token) => {
  const api = createApiWithToken(token);
  return api.get("/toprevenuecard");
};

export const fetchTopHighestStateMap = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophigheststatemap");
};

export const fetchTotalRevenueMonths = (token) => {
  const api = createApiWithToken(token);
  return api.get("/totalrevenuemonths");
};

export const fetchTotalRevenueRegions = (token) => {
  const api = createApiWithToken(token);
  return api.get("/totalrevenueregions");
};

export const fetchTopHighestCategories = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophighestcategories");
};

export const fetchTopHighestState = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophigheststate");
};

export const fetchTopHighestMarkets = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophighestmarkets");
};
