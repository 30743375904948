import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBQ9ZsRVZz7s_5Q-jIc4oIOMBw-yzutNKw",
  authDomain: "fathom-realty-support.firebaseapp.com",
  projectId: "fathom-realty-support",
  storageBucket: "fathom-realty-support.appspot.com",
  messagingSenderId: "542083377859",
  appId: "1:542083377859:web:ee3d1fae6e0b397bea5076",
  measurementId: "G-FGKEYBHKJX"
};


const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
