// src/hooks/useDashboardData.js
import { useEffect, useState } from "react";
import { fetchTotalBudgetCard, fetchTotalBudgetActual } from "./getApi.js";

const useDashboardData = (token) => {
  const [BudgetApi, setBudgetApi] = useState({
    totalbudgetcard: "",
    totalbudgetactual: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [totalbudgetcardRes, totalbudgetactualRes] = await Promise.all([
          fetchTotalBudgetCard(token),
          fetchTotalBudgetActual(token),
        ]);

        setBudgetApi({
          totalbudgetcard: totalbudgetcardRes.data, // access data from axios
          totalbudgetactual: totalbudgetactualRes.data,
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return { BudgetApi, loading, error };
};

export default useDashboardData;
