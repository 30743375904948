// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span {
  font-family: "Lato", sans-serif;
}

.App {
  text-align: center;
  box-sizing: border-box;
}

a {
  color: black;
  text-decoration: none;
}

button {
  cursor: pointer;
}



::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
/* 
::-webkit-scrollbar {
  display: none; 
}

 */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;;;AAIA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;AACA;EACE,oCAAoC;AACtC;AACA;;;;;EAKE","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap\");\r\n\r\nspan {\r\n  font-family: \"Lato\", sans-serif;\r\n}\r\n\r\n.App {\r\n  text-align: center;\r\n  box-sizing: border-box;\r\n}\r\n\r\na {\r\n  color: black;\r\n  text-decoration: none;\r\n}\r\n\r\nbutton {\r\n  cursor: pointer;\r\n}\r\n\r\n\r\n\r\n::-webkit-scrollbar {\r\n  width: 8px;\r\n  height: 5px;\r\n  position: absolute;\r\n}\r\n\r\n::-webkit-scrollbar-thumb {\r\n  background-color: rgba(0, 0, 0, 0.2);\r\n  border-radius: 4px;\r\n}\r\n::-webkit-scrollbar-thumb:hover {\r\n  background-color: rgba(0, 0, 0, 0.3);\r\n}\r\n/* \r\n::-webkit-scrollbar {\r\n  display: none; \r\n}\r\n\r\n */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
