
import React from 'react';
import './Loading.scss'
import { Flex, Spin } from 'antd';
const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 10,
};
const content = <div style={contentStyle} />;
const Loading = () => (
    <div className="frame_loading">
        <Flex gap="middle" vertical>
            <Flex gap="middle">
                <Spin tip={<span className='span_text_loading'>Loading...</span>} size="large">
                    {content}
                </Spin>
            </Flex>

        </Flex>
    </div>
);
export default Loading;