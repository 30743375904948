import { useEffect, useState } from "react";
import {
  fetchTotalCommissionMonth,
  fetchCommissionState,
  fetchTotalCard,
  fetchHighestMarkets,
  fetchHighestCategories,
} from "./getApi.js";

const useDashboardData = (token) => {
  const [CommissionApi, setCommissionApi] = useState({
    commissionmonths: "",
    commissionstates: "",
    totalcommission: "",
    highestmarkets: "",
    highestcategories: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          commissionmonthsRes,
          commissionstatesRes,
          totalcommissionRes,
          highestmarketsRes,
          highestcategoriesRes,
        ] = await Promise.all([
          fetchTotalCommissionMonth(token),
          fetchCommissionState(token),
          fetchTotalCard(token),
          fetchHighestMarkets(token),
          fetchHighestCategories(token),
        ]);

        setCommissionApi({
          commissionmonths: commissionmonthsRes.data, // access data from axios
          commissionstates: commissionstatesRes.data,
          totalcommission: totalcommissionRes.data,
          highestmarkets: highestmarketsRes.data,
          highestcategories: highestcategoriesRes.data,
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return { CommissionApi, loading, error };
};

export default useDashboardData;
