import { useState, useEffect } from "react";
import axios from "axios";
import * as echarts from "echarts";
import dataGeomap from "../../../data/USA_DATA.json";

const useFetchUsaMapData = (data) => {
  const [highestStates, setHighestStates] = useState([]);
  const [coordinates, setCoordinates] = useState({});

  useEffect(() => {
    if (!data || data.length === 0) return; 

    const fetchData = async () => {
      try {
        const apiKey = "8e94b11dd60b4aec915f5fa88e9b4917";

        
        const sortedStates = data.slice(0, 3);
        setHighestStates(sortedStates);

        
        const coords = {};
        for (const state of sortedStates) {
          const response = await axios.get(`https://api.opencagedata.com/geocode/v1/json`, {
            params: {
              q: state.name,
              key: apiKey,
            },
          });
          const result = response.data.results[0];
          if (result) {
            coords[state.name] = [result.geometry.lng, result.geometry.lat];
          }
        }
        setCoordinates(coords);

        // Register the USA map with custom positioning for some states
        echarts.registerMap("USA", dataGeomap, {
          Alaska: { left: -131, top: 50, width: 10 },
          Hawaii: { left: -131, top: 30, width: 8 },
          "Puerto Rico": { left: -76, top: 26, width: 2 },
        });
      } catch (error) {
        // console.error("Error fetching or processing the data:", error);
      }
    };

    fetchData();
  }, [data]);

  return { highestStates, coordinates };
};

export default useFetchUsaMapData;
