import axios from "axios";
import { URL } from "../../constant/url";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const createApiWithToken = (token) => {
  const requestToken = token || getTokenFromLocalStorage();
  const headers = {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  };

  if (requestToken) {
    headers["Authorization"] = `Bearer ${requestToken}`;
  }

  return axios.create({
    baseURL: `${URL}/api/transaction`,
    headers: headers,
  });
};

export const fetchTotalTransaction = (token) => {
  const api = createApiWithToken(token);
  return api.get("/totaltransaction");
};

export const fetchTopHighestSates = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophigheststates");
};

export const fetchTopHighestMarkets = (token) => {
  const api = createApiWithToken(token);
  return api.get("/tophighestmarkerts");
};

export const fetchTotalTransactionMonht = (token) => {
  const api = createApiWithToken(token);
  return api.get("/totaltransactionmonth");
};
