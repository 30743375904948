import React from "react";
import "./TotalTransactionCard.scss";
import { HomeOutlined } from "@ant-design/icons";
import { AnimatedValue } from "../../utils/animationnumber/AmimationNmber";

const TotalTransactionCard = ({ data, delays, durations }) => {
  return (
    <div
      className="frame_total_card_transaction"
      data-aos="flip-right"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="2000"
    >
      <div className="frame_title_icon_value">
        <div
          className="frame_title_icon"
          data-aos="fade-right"
          data-aos-delay="1000"
        >
          <span>{data.name}</span> <HomeOutlined className="icon_house" />
        </div>
        <div
          className="frame_value"
          data-aos="fade-right"
          data-aos-delay="2000"
        >
          <span>
            {
              <AnimatedValue
                endValue={parseInt(data.value)}
                delay={delays}
                durations={durations}
              />
            }
          </span>
        </div>
      </div>
    </div>
  );
};

export default TotalTransactionCard;
